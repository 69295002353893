import { ENDPOINT } from '@/lib/utils';
import axios from 'axios';
import { useEffect, useState } from 'react';

export const useCaptcha = () => {
  const [captcha, setCaptcha] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getCaptcha = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get('/simple/captcha', {
        params: { refresh: 1 },
      });
      setCaptcha(ENDPOINT + data.url);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCaptcha();
  }, []);

  return { captcha, getCaptcha, isLoading };
};
