'use client';

import { usePathname } from '@/i18n/routing';
import { AudioIcon, CloseIcon, LoadingIcon, MuteIcon, WarningIcon } from '@/icons';
import { useTouchAvailable } from '@/lib/hooks';
import { NAVBAR_Z_INDEX } from '@/lib/utils';
import { Box, Checkbox, Flex, Group, Modal, Radio } from '@mantine/core';
import {
  useDidUpdate,
  useDisclosure,
  useHover,
  useLocalStorage,
  useTextSelection,
  useWindowEvent,
} from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import { clsx } from 'clsx';
import { useLocale, useTranslations } from 'next-intl';
import { CSSProperties, memo, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { createPortal } from 'react-dom';
import classnames from './narrator.module.css';

type NarratorModelProps = {
  active: boolean;
  type: string;
  locale: string;
};

type TriggerProps = {
  selection: string;
  status: TriggerStatus;
  onClick: () => void;
};

type TriggerStatus = 'idle' | 'loading' | 'playing';

const ENDPOINT = 'https://api.muxlisa.uz/v1/api/services/tts/';
const TOKEN = 'pxJuGa7Nsj6zG5G5CP2u5Y8m4OXAANiBnQSqDGGf';

const TRIGGER_ICONS = {
  idle: <AudioIcon />,
  loading: <LoadingIcon />,
  playing: <AudioIcon />,
};

const NATIVE_LOCALES = ['oz', 'uz'];

export const Narrator = memo(function Narrator() {
  const t = useTranslations();
  const locale = useLocale();

  const [isActive, setIsActive] = useLocalStorage({
    key: 'is-narrator-active',
    defaultValue: true,
  });
  const [narratorType, setNarratorType] = useLocalStorage({
    key: 'narrator-type',
    defaultValue: '1',
  });

  return (
    <Flex direction="column" gap="md">
      <Flex justify="space-between" align="center">
        <h3 className={classnames.title}>{t('Ekran suhandoni')}:</h3>
        <NarratorInfo />
      </Flex>
      <Checkbox
        checked={isActive}
        onChange={(event) => setIsActive(event.currentTarget.checked)}
        label={t('Yoqish / O‘chirish')}
        aria-label={`${t('Ekran suhandoni')}: ${t('Yoqish / O‘chirish')}`}
      />
      {NATIVE_LOCALES.includes(locale) ? (
        <Radio.Group value={narratorType} onChange={setNarratorType}>
          <Group>
            <Radio
              value="1"
              label={t('Erkak ovozi')}
              disabled={!isActive}
              aria-label={`${t('Ekran suhandoni')}: ${t('Erkak ovozi')}`}
            />
            <Radio
              value="0"
              label={t('Ayol ovozi')}
              disabled={!isActive}
              aria-label={`${t('Ekran suhandoni')}: ${t('Ayol ovozi')}`}
            />
          </Group>
        </Radio.Group>
      ) : null}
      {isActive ? <NarratorModel active={isActive} type={narratorType} locale={locale} /> : null}
    </Flex>
  );
});

function NarratorModel({ active, type, locale }: NarratorModelProps) {
  const pathname = usePathname();
  const t = useTranslations();
  const selection = useTextSelection()?.toString() || '';
  const [triggerStatus, setTriggerStatus] = useState<TriggerStatus>('idle');
  const [audioUrl, setAudioUrl] = useState('');

  function handleIdle() {
    setTriggerStatus('idle');
    setAudioUrl('');
    if ('speechSynthesis' in window) speechSynthesis.cancel();
  }

  async function handleNativeSpeech() {
    setTriggerStatus('loading');
    try {
      const response = await axios({
        url: ENDPOINT,
        method: 'POST',
        responseType: 'blob',
        data: {
          token: TOKEN,
          audio_format: 'wav',
          speaker_id: type,
          text: selection,
        },
      });

      if (response?.data) {
        const audioUrl = URL.createObjectURL(response.data);

        setAudioUrl(audioUrl);
        setTriggerStatus('playing');
      }
    } catch (e) {
      console.log(e);
      handleIdle();
    }
  }

  function handleForeignSpeech() {
    if (!('speechSynthesis' in window)) return;

    const voices = getVoices();

    const speechData = new SpeechSynthesisUtterance();
    speechData.text = selection;

    const currentVoice = voices.find((voice) => voice?.lang?.toLowerCase()?.startsWith(locale));

    if (!currentVoice) {
      notifications.show({
        message: t('Sizning brauzeringiz ushbu tilda ekran suhandonini qo‘llab-quvvatlamaydi'),
        bg: '#fff3cd',
        icon: <WarningIcon />,
        classNames: {
          root: classnames.notification,
          icon: classnames.notification__icon,
          closeButton: classnames.notification__close,
        },
        closeButtonProps: {
          bg: 'transparent',
          icon: <CloseIcon />,
        },
      });
      return;
    }

    speechData.voice = currentVoice;
    speechData.lang = currentVoice.lang;
    speechData.pitch = 1;
    speechData.volume = 1;
    speechData.rate = 1;

    speechSynthesis.speak(speechData);
    speechData.onstart = () => setTriggerStatus('playing');
    speechData.onend = () => handleIdle();
    speechData.onerror = () => handleIdle();
  }

  function handleNarrator() {
    if (triggerStatus === 'loading') return;

    if (triggerStatus === 'playing') return handleIdle();

    if (NATIVE_LOCALES.includes(locale)) return handleNativeSpeech();

    handleForeignSpeech();
  }

  useDidUpdate(() => {
    if (!selection?.trim() || !active) handleIdle();
  }, [active, selection, locale]);

  useDidUpdate(() => {
    handleIdle();
    if (window.getSelection) {
      const selection = window.getSelection();
      selection?.removeAllRanges();
    }
  }, [pathname]);

  useEffect(() => {
    const voices = getVoices();

    if (voices?.length == 0) window.speechSynthesis.addEventListener('voiceschanged', () => getVoices());
    return () => window.speechSynthesis.removeEventListener('voiceschanged', () => getVoices());
  }, []);

  return (
    <Box hidden>
      {createPortal(<Trigger selection={selection} status={triggerStatus} onClick={handleNarrator} />, document.body)}
      {audioUrl && (
        <audio autoPlay onEnded={handleIdle}>
          <source src={audioUrl} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
      )}
    </Box>
  );
}

function Trigger({ selection, status, onClick }: TriggerProps) {
  const t = useTranslations();
  const { hovered, ref: trigger } = useHover<HTMLButtonElement>();
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isActive, setIsActive] = useState(false);
  const isTouchAvailable = useTouchAvailable();

  function handleTriggerPosition(e: PointerEvent) {
    if (trigger?.current?.contains(e.target as HTMLElement)) return;

    const top = e.pageY + 32 >= document.body.clientHeight ? e.pageY - 80 : e.pageY; /* 32 = trigger's height */
    const left = window.innerWidth - e.pageX < 80 ? e.pageX - 80 : e.pageX;

    setPosition({ top, left });
    setIsActive(true);
  }

  function closeTrigger(e: PointerEvent) {
    if (trigger?.current?.contains(e.target as HTMLElement)) return;
    setIsActive(false);
  }

  useWindowEvent('pointerup', handleTriggerPosition);
  useWindowEvent('pointerdown', closeTrigger);

  const triggerStyles: CSSProperties = isTouchAvailable
    ? {
        position: 'fixed',
        bottom: isMobile ? 10 : 55,
        left: isMobile ? 20 : 55,
        width: 42,
        height: 42,
      }
    : {
        position: 'absolute',
        top: position.top,
        left: position.left,
      };

  const onMobileActive = selection.trim();
  const onDesktopActive = isActive && onMobileActive;
  const isTriggerActive = isTouchAvailable ? onMobileActive : onDesktopActive;

  return (
    <button
      onClick={onClick}
      style={{
        ...triggerStyles,
        zIndex: NAVBAR_Z_INDEX * 2,
      }}
      className={clsx(classnames.trigger, classnames[status], {
        [classnames.active]: isTriggerActive,
      })}
      ref={trigger}
      aria-label={t('Ekran suhandoni')}
    >
      {(hovered || isTouchAvailable) && status === 'playing' ? <MuteIcon /> : TRIGGER_ICONS[status]}
    </button>
  );
}

function NarratorInfo() {
  const t = useTranslations();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <button className={classnames.question__mark} onClick={open} aria-label={t('Ekran suhandoni nima?')}>
        ?
      </button>
      <Modal.Root opened={opened} onClose={close} size={500} zIndex={NAVBAR_Z_INDEX + 2}>
        <Modal.Overlay backgroundOpacity={0.3} />
        <Modal.Content>
          <Modal.Header className="border-b-[1px] border-zinc-200">
            <Modal.Title className="text-xl text-neutral-500 font-medium">{t('Ekran suhandoni nima?')}</Modal.Title>
            <Modal.CloseButton
              icon={<CloseIcon />}
              className={classnames.drawer__close}
              aria-label={t('{text}ni yopish', { text: `"${t('Ekran suhandoni nima?')}"` })}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="pt-5 text-sm text-neutral-800">
              {t(
                'ekran-suhandoni-zaif-ko-radigan-foydalanuvchilar-uchun-mo-ljallangan-bo-lib-sahifalardagi-matnni-ovozli-o-qittirish-imkonini-beradi-ushbu-funksiyani-ishga-tushirish-uchun-kerakli-so-zlarni-belgilash-va-so-zning-oldidan-chiqgan-tugmani-bosish-kifoya'
              )}
            </p>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}

function getVoices() {
  let voices = speechSynthesis.getVoices();
  if (!voices.length) {
    const utterance = new SpeechSynthesisUtterance('');
    speechSynthesis.speak(utterance);
    voices = speechSynthesis.getVoices();
  }

  return voices;
}
