import { useAccessibilityStore } from '@/lib/store';
import { Flex, SegmentedControl } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import classnames from '../accessibility.module.css';

const ATTRIBUTE_KEY = 'data-word-spacing';

export default function WordSpacing() {
  const t = useTranslations();
  const { wordSpacing, setWordSpacing } = useAccessibilityStore((store) => store);

  useEffect(() => {
    const root = document.documentElement;

    if (wordSpacing === 'normal' && root.hasAttribute(ATTRIBUTE_KEY)) {
      root.removeAttribute(ATTRIBUTE_KEY);
    } else {
      root.setAttribute(ATTRIBUTE_KEY, wordSpacing);
    }
  }, [wordSpacing]);

  return (
    <Flex direction="column" gap="xs">
      <h3 className={classnames.title}>{t('so-z-oralig-i')}:</h3>
      <SegmentedControl
        fullWidth
        data={[
          { label: t('normal'), value: 'normal' },
          { label: t('katta'), value: 'big' },
          { label: t('kattaroq'), value: 'bigger' },
        ]}
        value={wordSpacing}
        onChange={setWordSpacing}
      />
    </Flex>
  );
}
