import { useAccessibilityStore } from '@/lib/store';
import { Flex, SegmentedControl } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import classnames from '../accessibility.module.css';

const ATTRIBUTE_KEY = 'data-word-height';

export default function WordHeight() {
  const t = useTranslations();
  const { wordHeight, setWordHeight } = useAccessibilityStore((store) => store);

  useEffect(() => {
    const root = document.documentElement;

    if (wordHeight === 'normal' && root.hasAttribute(ATTRIBUTE_KEY)) {
      root.removeAttribute(ATTRIBUTE_KEY);
    } else {
      root.setAttribute(ATTRIBUTE_KEY, wordHeight);
    }
  }, [wordHeight]);

  return (
    <Flex direction="column" gap="xs">
      <h3 className={classnames.title}>{t('so-z-balandligi')}:</h3>
      <SegmentedControl
        fullWidth
        data={[
          { label: t('normal'), value: 'normal' },
          { label: t('katta'), value: 'big' },
          { label: t('kattaroq'), value: 'bigger' },
        ]}
        value={wordHeight}
        onChange={setWordHeight}
      />
    </Flex>
  );
}
