'use client';

import { News, NewsQueryParams } from '@/lib/types';
import { Empty } from '@/ui';
import { PaginationWidget } from '@/widgets';
import dynamic from 'next/dynamic';
import { useParams, useSearchParams } from 'next/navigation';
import { Suspense } from 'react';
import useSWR from 'swr';
import { NewsList } from './NewsList';
const NewsCard = dynamic(() => import('@/widgets').then((mod) => mod.NewsCard));
const NewsCardLoading = dynamic(() => import('@/widgets').then((mod) => mod.NewsCard.Loading));

type Props = { queryParams: NewsQueryParams };

type Data = {
  data: News[];
  total_page: number;
};

export function NewsContent({ queryParams }: Props) {
  const searchParams = useSearchParams();
  const { news_code } = useParams<{ news_code: string }>();

  const { data: news, isLoading } = useSWR<Data>([
    '/authorities/news/category',
    { params: { code_name: news_code, page: searchParams.get('page'), ...queryParams } },
  ]);

  return (
    <>
      <NewsList>
        {isLoading ? (
          <NewsCardLoading count={6} />
        ) : news?.data && news?.data?.length > 0 ? (
          news?.data?.map((item) => <NewsCard key={item?.id} {...item} url={`/news/view/${item?.id}`} type="news" />)
        ) : (
          <Empty />
        )}
      </NewsList>
      <Suspense>
        <PaginationWidget totalPage={news?.total_page} />
      </Suspense>
    </>
  );
}
