import { create } from 'zustand';

type State = {
  height: number;
};

type Action = {
  setHeight: (height: State['height']) => void;
};

export const useHeaderStore = create<State & Action>((set) => ({
  height: 200,
  setHeight: (height) => set(() => ({ height })),
}));
