'use client';

import { Button, Collapse, Container, Flex, Select, SimpleGrid, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import axios from 'axios';
import dayjs from 'dayjs';
import { useLocale, useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { Suspense, useEffect, useState } from 'react';

import { usePathname, useRouter } from '@/i18n/routing';
import { SearchIcon } from '@/icons';
import { useQueryString } from '@/lib/hooks';
import { AUTHORITY_CODE, capitalize, sanitizeInput } from '@/lib/utils';
import { Empty, LinkBox, Section } from '@/ui';
import { Content, PaginationWidget } from '@/widgets';
import { getHotkeyHandler } from '@mantine/hooks';
import classnames from './search.module.css';

type FormValues = {
  text: string;
  category: string | null;
  start_date: Date | null;
  end_date: Date | null;
};

type Result = {
  data?: { id: number; title: string; link: string; breadcrumb?: string; anons?: string }[];
  total_page?: number;
  current_page?: number;
};

const CATEGORIES = [
  {
    value: 'pages',
    label: 'Sahifalar',
  },
  {
    value: 'guides',
    label: 'Rahbarlar',
  },
  {
    value: 'staff',
    label: 'Markaziy apparat',
  },
  {
    value: 'regional',
    label: 'Hududiy boshqarmalar',
  },
  {
    value: 'subordinate',
    label: 'Tizimdagi tashkilotlar',
  },
  {
    value: 'activity',
    label: 'Faoliyatlar',
  },
  {
    value: 'faq',
    label: 'Savol-javoblar',
  },
  {
    value: 'sections',
    label: 'Bo‘limlar',
  },
  {
    value: 'news',
    label: 'Axborot xizmati',
  },
];

const TODAY = new Date();

export function Search() {
  const t = useTranslations();
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const createQueryString = useQueryString(searchParams.toString());
  const [result, setResult] = useState<Result>();
  const [isLoading, setIsLoading] = useState(true);

  const initialValues = {
    text: searchParams.get('text') || '',
    category: searchParams.get('category') || null,
    start_date: searchParams.get('start_date') ? new Date(searchParams.get('start_date') as string) : null,
    end_date: searchParams.get('end_date') ? new Date(searchParams.get('end_date') as string) : null,
  };

  const form = useForm<FormValues>({
    initialValues,
  });

  const getResult = async () => {
    if (!form.getValues().text?.trim()) return setIsLoading(false);
    setIsLoading(true);
    try {
      const url = form.getValues().category?.trim()
        ? '/authorities/lists/advanced-search'
        : '/authorities/lists/search';
      const { data } = await axios.get(url, {
        headers: { language: locale, code: AUTHORITY_CODE },
        params: { ...form.getValues(), page: searchParams.get('page') || 1 },
      });

      if (data?.data) setResult(data);
      else setResult({});
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      form.resetTouched();
    }
  };

  const handleSubmit = async (formValues: FormValues) => {
    if (!form.isTouched() || isLoading) return;

    const values = {
      text: sanitizeInput(formValues?.text),
      start_date: formValues.start_date ? dayjs(formValues.start_date).format('YYYY-MM-DD') : null,
      end_date: formValues.end_date ? dayjs(formValues.end_date).format('YYYY-MM-DD') : null,
      category: formValues?.category,
    };

    setIsLoading(true);
    const PATH = `${pathname}?${createQueryString({ ...values, page: null })}`;
    router.replace(PATH, { scroll: false });
  };

  const resetDates = () => {
    if (form.getValues().category === 'news') return;
    form.setFieldValue('start_date', null);
    form.setFieldValue('end_date', null);
  };

  useEffect(() => {
    form.setValues(initialValues);
    getResult();
  }, [searchParams?.toString()]);

  return (
    <Container>
      <form
        onSubmit={form.onSubmit(handleSubmit)}
        onKeyUp={getHotkeyHandler([['Enter', () => handleSubmit(form.getValues())]])}
        className="w-full px-20 py-10 3xl:px-14 3xl:py-7 lg:px-10 lg:py-5 sm:p-5"
      >
        <Flex align="flex-end" gap="xl" className="lg:flex-col">
          <Flex w="100%" direction="column" className="grow">
            <SimpleGrid cols={{ base: 1, lg: 2 }} spacing="xl" verticalSpacing="md">
              <TextInput
                {...form.getInputProps('text')}
                label={t('Kalit so‘zlar')}
                placeholder={t('Kalit so‘zlar')}
                radius="sm"
                classNames={classnames}
                withAsterisk
              />
              <Select
                value={form.getValues().category}
                onChange={(value) => form.setFieldValue('category', value)}
                data={CATEGORIES.map(({ value, label }) => ({
                  value,
                  label: t(label),
                }))}
                label={t('Toifalar')}
                placeholder={t('Toifalar')}
                clearable
                radius="sm"
                classNames={classnames}
                comboboxProps={{ withinPortal: false }}
                clearButtonProps={{
                  'aria-label': t('{text}ni yopish', { text: t('Toifalar') }),
                }}
              />
            </SimpleGrid>
            <Collapse in={form.getValues().category === 'news'} onTransitionEnd={resetDates}>
              <SimpleGrid cols={{ base: 1, md: 2 }} spacing="xl" verticalSpacing="md" pt="md">
                <DateInput
                  {...form.getInputProps('start_date')}
                  classNames={classnames}
                  clearable
                  valueFormat="YYYY-MM-DD"
                  maxDate={form.getValues().end_date || TODAY}
                  label={capitalize(t('bo‘lgan davr dan'))}
                  placeholder={capitalize(t('bo‘lgan davr dan'))}
                />
                <DateInput
                  {...form.getInputProps('end_date')}
                  classNames={classnames}
                  clearable
                  valueFormat="YYYY-MM-DD"
                  minDate={form.getValues().start_date || undefined}
                  maxDate={TODAY}
                  label={capitalize(t('davr gacha'))}
                  placeholder={capitalize(t('davr gacha'))}
                />
              </SimpleGrid>
            </Collapse>
          </Flex>
          <Button
            type="submit"
            size="lg"
            display="block"
            fz="sm"
            lts={1}
            loaderProps={{ type: 'dots' }}
            rightSection={<SearchIcon />}
            classNames={{ section: classnames.btn__icon }}
            loading={isLoading}
            className="shrink-0 sm:w-full"
          >
            {t('Qidiruv')}
          </Button>
        </Flex>
      </form>
      <Section>
        <Content className="flex flex-col gap-5">
          {isLoading ? (
            <LinkBox.Loading count={5} />
          ) : result?.data && result?.data?.length > 0 ? (
            result?.data?.map((item) => (
              <LinkBox url={item.link} breadcrumb={item?.breadcrumb || ''} anons={item?.anons || ''} key={item.id}>
                {item.title}
              </LinkBox>
            ))
          ) : (
            <Empty />
          )}
          <Suspense>
            <PaginationWidget totalPage={result?.total_page} />
          </Suspense>
        </Content>
      </Section>
    </Container>
  );
}
