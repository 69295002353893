'use client';

import { usePathname } from '@/i18n/routing';
import { createURL, DOMAIN } from '@/lib/utils';
import { SocialMediaIcon } from '@/ui';
import { Flex } from '@mantine/core';
import { useLocale } from 'next-intl';

export function Share() {
  const pathname = usePathname();
  const locale = useLocale();
  const URL = createURL(`${DOMAIN}/${locale}${pathname}`);

  return (
    <Flex align="center" gap="md">
      <SocialMediaIcon type="facebook" url={`https://facebook.com/sharer/sharer.php?u=${URL}`} position="bottom" />
      <SocialMediaIcon type="twitter" url={`https://x.com/intent/tweet?url=${URL}`} position="bottom" />
      <SocialMediaIcon type="telegram" url={`https://t.me/share/url?url=${URL}`} position="bottom" />
    </Flex>
  );
}
