'use client';

import { PrintIcon } from '@/icons';
import { Views } from '@/ui';
import { Share } from '@/widgets';
import { Box, Divider, Flex, Paper, Tooltip } from '@mantine/core';
import { clsx } from 'clsx';
import { useTranslations } from 'next-intl';
import { forwardRef } from 'react';
import classnames from './content.module.css';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

type HeaderProps = Props & {
  details?: {
    ageLimit?: number;
    handlePrint?: () => void;
  };
};

type BodyProps = Props & {
  isShareable?: boolean;
  views?: number | null;
  updatedAt?: string | null;
};

const Content = forwardRef<HTMLDivElement, Props>(function Content({ children, className }, ref) {
  return (
    <Paper
      className={clsx('px-20 py-10 3xl:px-14 3xl:py-7 lg:px-10 lg:py-5 sm:p-5 printable', classnames.paper, className)}
      ref={ref}
      id="main-content"
    >
      {children}
    </Paper>
  );
});

function ContentHeader({ children, details = {} }: HeaderProps) {
  const t = useTranslations();
  if (!details) return <>{children}</>;
  const { ageLimit, handlePrint } = details;

  return (
    <Flex w="100%" justify={children ? 'space-between' : 'flex-end'} align="center" gap="sm" pb="sm">
      {children}
      <Flex align="center" gap="md">
        {ageLimit !== undefined && (
          <>
            <Tooltip
              label={t('Yosh chegarasi')}
              events={{ hover: true, focus: true, touch: false }}
              withArrow
              arrowSize={5}
              offset={5}
              color="#cc950f"
              position="bottom"
            >
              <span className={classnames.age__limit}>{ageLimit}+</span>
            </Tooltip>
            <Divider orientation="vertical" bg="gray" />
          </>
        )}

        {handlePrint && (
          <Tooltip
            label={t('Sahifani chop etish')}
            events={{ hover: true, focus: true, touch: false }}
            withArrow
            arrowSize={5}
            offset={5}
            maw={180}
            multiline={true}
            color="#cc950f"
            position="bottom"
            className="text-center"
          >
            <button onClick={handlePrint} className={classnames['print--btn']} aria-label={t('Sahifani chop etish')}>
              <PrintIcon />
            </button>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
}

function ContentTitle({ children, className }: Props) {
  return <h2 className={clsx('pt-2 pb-4 uppercase', classnames.title, className)}>{children}</h2>;
}

function ContentBody({ children, className, isShareable = false, views = null, updatedAt = null }: BodyProps) {
  const t = useTranslations();

  return (
    <Flex direction="column" gap="xl">
      <Box className={className}>{children}</Box>
      {!isShareable && !views && !updatedAt ? null : (
        <Flex direction="column" gap="sm">
          <Flex justify="space-between">
            {isShareable ? <Share /> : null}
            {views ? <Views count={views} /> : null}
          </Flex>
          {updatedAt ? <p className={classnames.updatedAt}>{`${t('Oxirgi yangilanish')}: ${updatedAt}`}</p> : null}
        </Flex>
      )}
    </Flex>
  );
}

const ContentNamespace = Object.assign(Content, {
  Header: ContentHeader,
  Title: ContentTitle,
  Body: ContentBody,
});

export { ContentNamespace as Content };
