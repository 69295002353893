import { useAccessibilityStore } from '@/lib/store';
import { Flex, Slider } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import classnames from './accessibility.module.css';

const defaultFontSizes: { [key: string]: number } = {
  '--font-xxsm': 0.625,
  '--font-xsm': 0.875,
  '--font-sm': 1,
  '--font': 1.125,
  '--font-md': 1.25,
  '--font-lg': 1.5,
  '--font-xlg': 1.875,
  '--font-xxlg': 2.25,
  '--font-xxxlg': 3,
};

const fontSizeMarks = [
  { value: 10, label: '10%' },
  { value: 20, label: '20%' },
  { value: 30, label: '30%' },
  { value: 40, label: '40%' },
  { value: 50, label: '50%' },
  { value: 60, label: '60%' },
  { value: 70, label: '70%' },
  { value: 80, label: '80%' },
  { value: 90, label: '90%' },
  { value: 100, label: '100%' },
];

function FontSize() {
  const t = useTranslations();
  const { fontSize, setFontSize } = useAccessibilityStore((store) => store);

  useEffect(() => {
    const root = document.documentElement;

    Object.keys(defaultFontSizes).forEach((key) => {
      const baseFontSize = defaultFontSizes[key];
      const calculatedFontSizeRem = baseFontSize + (fontSize - 50) / 100; // Adjust this calculation as needed
      root.style.setProperty(key, `${calculatedFontSizeRem}rem`);
    });
  }, [fontSize]);

  return (
    <Flex direction="column" gap="xs" pb={24}>
      <h3 className={classnames.title}>{t('Shrift o‘lchami')}:</h3>
      <Slider
        value={fontSize}
        onChange={setFontSize}
        step={10}
        min={10}
        marks={fontSizeMarks}
        label={(val) => fontSizeMarks.find((mark) => mark.value === val)?.label}
        classNames={{ markLabel: classnames.markLabel, label: classnames.label }}
        labelAlwaysOn
        thumbLabel={`${t('Shrift o‘lchami')}: ${fontSize}%`}
      />
    </Flex>
  );
}

export default FontSize;
