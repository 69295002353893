'use client';

import { Box } from '@mantine/core';
import { clsx } from 'clsx';
import sanitizeHtml from 'sanitize-html';
import classnames from './body.module.css';

type Props = { content: string; children?: React.ReactNode; className?: string };

export function Body({ content, children, className = '' }: Props) {
  const sanitizedContent = sanitizeHtml(content, {
    allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img', 'iframe'],
    allowedAttributes: Object.assign(sanitizeHtml.defaults.allowedAttributes, {
      '*': [
        'src',
        'alt',
        'class',
        'style',
        'align',
        'width',
        'height',
        'colspan',
        'rowspan',
        'cellpadding',
        'cellspacing',
        'border',
      ],
    }),
    allowedSchemes: [...sanitizeHtml.defaults.allowedSchemes, 'data'],
    allowedClasses: {
      '*': false,
    },
  });

  return (
    <Box className={clsx(classnames.body, className)}>
      <Box dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
      {children}
    </Box>
  );
}
