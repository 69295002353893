'use client';

import { clsx } from 'clsx';

export function Title({ text, className = '' }: { text: string; className?: string }) {
  return (
    <h2 className={clsx('font--lg font-bold uppercase', className)} style={{ color: 'var(--color-text-primary)' }}>
      {text}
    </h2>
  );
}
