'use client';

import { Section, ThreeStack } from '@/ui';
import dynamic from 'next/dynamic';
const Weather = dynamic(() => import('./Weather'));
const Banner = dynamic(() => import('./Banner'));
const Currency = dynamic(() => import('./Currency'));

export function ExternalInfo() {
  return (
    <Section>
      <ThreeStack flexible className="place-items-center gap-20">
        <Weather />
        <Banner />
        <Currency />
      </ThreeStack>
    </Section>
  );
}
