'use client';

import { AUTHORITY_CODE } from '@/lib/utils';
import Script from 'next/script';
import { useState } from 'react';
import useSWR from 'swr';

export function OnlineConsultant() {
  const [srcVerboxLink, setSrcVerboxLink] = useState('https://admin.verbox.ru/support/support.js');

  const { data: chat } = useSWR<{ value: string }>(
    AUTHORITY_CODE !== 'soliq' ? ['/authorities/main/get-chat-id'] : null
  );

  if (AUTHORITY_CODE === 'soliq') {
    return (
      <>
        <Script src="https://www.chatbase.co/embed.min.js" strategy="lazyOnload" defer />;
        <Script id="soliq-chatbot-script" strategy="lazyOnload" defer>
          {`window.embeddedChatbotConfig = {
              chatbotId: "34Rn-LeaYcF6VilAkErpt",
              domain: "www.chatbase.co"
            }`}
        </Script>
      </>
    );
  }

  return chat?.value?.trim() ? (
    <Script
      src={`${srcVerboxLink}?h=${chat?.value}`}
      strategy="lazyOnload"
      defer
      onError={() => {
        setSrcVerboxLink('https://static.site-chat.me/support/support.int.js');
      }}
    />
  ) : null;
}
