'use client';
import { useAccessibilityStore } from '@/lib/store';
import { Flex, Slider } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import classnames from './accessibility.module.css';

const scaleSizeMarks = [
  { value: 60, label: '60%' },
  { value: 80, label: '80%' },
  { value: 100, label: '100%' },
  { value: 120, label: '120%' },
  { value: 140, label: '140%' },
  { value: 160, label: '160%' },
  { value: 180, label: '180%' },
  { value: 200, label: '200%' },
];

function ZoomInOut() {
  const t = useTranslations();
  const { scaleSize, setScaleSize } = useAccessibilityStore((store) => store);

  useEffect(() => {
    document.documentElement.style.fontSize = `${scaleSize}%`;
  }, [scaleSize]);

  return (
    <Flex direction="column" gap="xs" pb={24}>
      <h3 className={classnames.title}>{t('Masshtab')}:</h3>
      <Slider
        value={scaleSize}
        onChange={setScaleSize}
        step={20}
        min={60}
        max={200}
        marks={scaleSizeMarks}
        label={(val) => scaleSizeMarks.find((mark) => mark.value === val)?.label}
        classNames={{ markLabel: classnames.markLabel, label: classnames.label }}
        labelAlwaysOn
        thumbLabel={`${t('Masshtab')}: ${scaleSizeMarks.find((mark) => mark.value === scaleSize)?.label}`}
      />
    </Flex>
  );
}

export default ZoomInOut;
