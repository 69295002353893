import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type ThemeCode = 'default' | 'contrast' | 'blackwhite';

type State = {
  theme: ThemeCode;
  haveImage: boolean;
  fontSize: number;
  scaleSize: number;
  wordHeight: string;
  wordSpacing: string;
  letterSpacing: string;
  concentrate: boolean;
};

type Action = {
  setTheme: (height: State['theme']) => void;
  setHaveImage: (haveImage: State['haveImage']) => void;
  setFontSize: (fontSize: State['fontSize']) => void;
  setScaleSize: (fontSize: State['scaleSize']) => void;
  setWordHeight: (wordHeight: State['wordHeight']) => void;
  setWordSpacing: (wordSpacing: State['wordSpacing']) => void;
  setLetterSpacing: (letterSpacing: State['letterSpacing']) => void;
  setConcentrate: (concentrate: State['concentrate']) => void;
  getIsDefaultSettings: () => boolean;
  resetAccessibiltyTools: () => void;
};

const DEFAULT_THEME = 'default';
const DEFAULT_HAVE_IMAGE = true;
const DEFAULT_FONT_SIZE = 50;
const DEFAULT_SCALE_SIZE = 100;
const DEFAULT_WORD_HEIGHT = 'normal';
const DEFAULT_WORD_SPACING = 'normal';
const DEFAULT_LETTER_SPACING = 'normal';
const DEFAULT_CONCENTRATE = false;

const initialState: State = {
  theme: DEFAULT_THEME,
  haveImage: DEFAULT_HAVE_IMAGE,
  fontSize: DEFAULT_FONT_SIZE,
  scaleSize: DEFAULT_SCALE_SIZE,
  wordHeight: DEFAULT_WORD_HEIGHT,
  wordSpacing: DEFAULT_WORD_SPACING,
  letterSpacing: DEFAULT_LETTER_SPACING,
  concentrate: DEFAULT_CONCENTRATE,
};

const checkIsDefault = (state: State) => {
  return (
    state.theme === DEFAULT_THEME &&
    state.haveImage === DEFAULT_HAVE_IMAGE &&
    state.fontSize === DEFAULT_FONT_SIZE &&
    state.scaleSize === DEFAULT_SCALE_SIZE &&
    state.wordHeight === DEFAULT_WORD_HEIGHT &&
    state.wordSpacing === DEFAULT_WORD_SPACING &&
    state.letterSpacing === DEFAULT_LETTER_SPACING &&
    state.concentrate === DEFAULT_CONCENTRATE
  );
};

export const useAccessibilityStore = create(
  persist<State & Action>(
    (set, get) => ({
      ...initialState,
      setTheme: (theme) => set(() => ({ theme })),
      setHaveImage: (haveImage) => set(() => ({ haveImage })),
      setFontSize: (fontSize) => set(() => ({ fontSize })),
      setScaleSize: (scaleSize) => set(() => ({ scaleSize })),
      setWordHeight: (wordHeight) => set(() => ({ wordHeight })),
      setWordSpacing: (wordSpacing) => set(() => ({ wordSpacing })),
      setLetterSpacing: (letterSpacing) => set(() => ({ letterSpacing })),
      setConcentrate: (concentrate) => set(() => ({ concentrate })),
      getIsDefaultSettings: () => checkIsDefault(get()),
      resetAccessibiltyTools: () => set(initialState),
    }),
    {
      name: 'accessibility-tool-values',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
