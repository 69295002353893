'use client';

import { useMediaQuery } from '@mantine/hooks';
import { m, MotionProps, useReducedMotion } from 'framer-motion';
import React from 'react';

type Direction = 'up' | 'down' | 'left' | 'right';

type DirectionValue = {
  y?: number;
  x?: number;
};

type AProps = MotionProps & {
  direction?: Direction;
  from?: DirectionValue;
  to?: DirectionValue;
  duration?: number;
  delay?: number;
  className?: string;
  children: React.ReactNode;
};

const DIRECTIONS = {
  up: { from: { y: 100 }, to: { y: 0 } },
  down: { from: { y: -100 }, to: { y: 0 } },
  left: { from: { x: 100 }, to: { x: 0 } },
  right: { from: { x: -100 }, to: { x: 0 } },
};

export function ScrollAnimation(props: AProps) {
  const {
    children,
    direction = 'up',
    from = DIRECTIONS[direction].from,
    to = DIRECTIONS[direction].to,
    duration = 0.8,
    delay = 0,
    className = '',
    ...otherProps
  } = props;

  const isMiniDevice = useMediaQuery('(max-width: 600px)');
  const shouldReduceMotion = useReducedMotion();

  if (isMiniDevice || shouldReduceMotion) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <m.div
      viewport={{ once: true }}
      initial={{ opacity: 0, ...from }}
      whileInView={{ opacity: 1, ...to }}
      transition={{ type: 'spring', bounce: 0.3, duration, delay }}
      className={className}
      {...otherProps}
    >
      {children}
    </m.div>
  );
}
