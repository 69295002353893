import { useTouchAvailable } from '@/lib/hooks';
import { useAccessibilityStore } from '@/lib/store';
import { Button, Collapse, Divider, Flex, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import Concentrate from './Concentrate';
import LetterSpacing from './LetterSpacing';
import WordHeight from './WordHeight';
import WordSpacing from './WordSpacing';

export function ReadingMode() {
  const t = useTranslations();
  const getIsDefaultSettings = useAccessibilityStore((store) => store.getIsDefaultSettings);
  const [opened, { toggle }] = useDisclosure(!getIsDefaultSettings());
  const isTouchAvailable = useTouchAvailable();

  return (
    <Flex direction="column" gap="xs">
      <Button variant={opened ? 'outline' : 'default'} size="md" onClick={toggle}>
        {t('o-qish-rejimi')}
      </Button>
      <Collapse in={opened}>
        <Stack gap={18} pt={12}>
          <WordHeight />
          <Divider />
          <WordSpacing />
          <Divider />
          <LetterSpacing />
          {isTouchAvailable ? null : (
            <>
              <Divider />
              <Concentrate />
            </>
          )}
        </Stack>
      </Collapse>
    </Flex>
  );
}
