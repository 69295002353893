import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

export const usePrint = () => {
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef,
    pageStyle: 'padding: 2cm;',
  });

  return { ref: contentRef, handlePrint };
};
