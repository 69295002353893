'use client';

import { ChevronLeftIcon } from '@/icons';
import { StringList } from '@/lib/types';

type Props = {
  style?: StringList;
  onClick?: () => void;
  classList?: string;
};

export function SliderArrow({ style, onClick, classList }: Props) {
  return (
    <span className={classList}>
      <ChevronLeftIcon style={style} onClick={onClick} />
    </span>
  );
}
