'use client';

import { SearchIcon } from '@/icons';
import { Autocomplete, ComboboxProps, useCombobox } from '@mantine/core';
import { useClickOutside, useDidUpdate, useWindowEvent } from '@mantine/hooks';
import { clsx } from 'clsx';
import { useLocale, useTranslations } from 'next-intl';
import { useRouter } from 'next-nprogress-bar';
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';
import classnames from './input.module.css';

type Props = {
  data: string[];
  placeholder: string;
  withinModal?: boolean;
};

const SEARCH_KEY = 'text';

export function Input({ data, placeholder, withinModal = false }: Props) {
  const t = useTranslations();
  const router = useRouter();
  const locale = useLocale();
  const searchParams = useSearchParams();
  const [value, setValue] = useState(searchParams.get('text') || '');
  const [opened, setOpened] = useState(false);
  const combobox = useCombobox({ opened: withinModal ? true : opened });
  const ref = useClickOutside(() => setOpened(false), ['mouseup']);

  const handleSubmit = (value: string) => {
    if (!value?.trim()) return;
    setOpened(false);
    setValue(value);

    router.push(`/${locale}/search?${SEARCH_KEY}=${value}`);
  };

  const onChange = (currentValue: string) => {
    setOpened(true);
    setValue(currentValue);
  };

  const comboboxProps: ComboboxProps = {
    onOptionSubmit: handleSubmit,
    store: combobox,
    withinPortal: !withinModal,
  };

  useDidUpdate(() => {
    if (searchParams.get(SEARCH_KEY) && searchParams.get(SEARCH_KEY) !== value) {
      setValue(searchParams.get(SEARCH_KEY) || '');
    }
  }, [searchParams.get(SEARCH_KEY)]);

  useWindowEvent('keydown', (event) => {
    if (!opened) return;

    if (value?.trim() && event.code === 'Enter') {
      event.preventDefault();
      handleSubmit(value);
    }

    if (event.code === 'Tab' || (event.code === 'Tab' && event.shiftKey)) {
      event.preventDefault();
      setOpened(false);
    }
  });

  return (
    <Autocomplete
      classNames={{
        ...classnames,
        root: clsx(classnames.root, { [classnames.withinModal]: withinModal }),
      }}
      radius="sm"
      placeholder={`${t(placeholder)}...`}
      rightSectionPointerEvents="all"
      rightSectionProps={{
        role: 'button',
        className: classnames.button,
        onClick: () => handleSubmit(value),
        'aria-label': t('Qidiruv'),
      }}
      rightSection={<SearchIcon />}
      data={data}
      value={value}
      onChange={onChange}
      onFocus={() => setOpened(true)}
      limit={5}
      maxDropdownHeight={withinModal ? 300 : 200}
      comboboxProps={comboboxProps}
      ref={ref}
      aria-label={t(placeholder)}
    />
  );
}
