'use client';

import { BellIcon, CheckIcon, CloseIcon } from '@/icons';
import { StringList } from '@/lib/types';
import { AUTHORITY_CODE, NAVBAR_Z_INDEX } from '@/lib/utils';
import { Button, Center, Divider, Flex, Modal, Text } from '@mantine/core';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import axios from 'axios';
import { clsx } from 'clsx';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { useLocale, useTranslations } from 'next-intl';
import { useEffect } from 'react';
import useSWR from 'swr';
import classnames from './push-notification.module.css';

const MAIN_ROUTE = 'gov.uz';
const ALLOWED_ROUTES = 'notification-allowed-routes';
const DISALLOWED_ROUTES = 'notification-disallowed-routes';
const modalTitle: StringList = {
  oz: 'Modal',
  uz: 'Модал',
  qr: 'modal ayna',
  ru: 'модальное окно',
  en: 'modal',
};

const firebaseConfig = {
  apiKey: 'AIzaSyCBFMFlR6xyvUldRKuWfS2B0gX7LqCxKTo',
  authDomain: 'govuz-d9cc4.firebaseapp.com',
  projectId: 'govuz-d9cc4',
  storageBucket: 'govuz-d9cc4.appspot.com',
  messagingSenderId: '628225219957',
  appId: '1:628225219957:web:b728e2d7bc006fd4b5c73c',
};

const FIREBASE_VAPID_KEY = 'BPygnRZHQmiRJPip3_By4uoB4vzFHW0cDv0cOOdPS48FwMkm-ZhNhQitC7ff0Haib6FVnvOveytvCLg4xfCqoQw';

const firebaseApp = initializeApp(firebaseConfig);

export function PushNotification() {
  const t = useTranslations();
  const locale = useLocale();
  const [opened, { open, close }] = useDisclosure(false);

  const [oldToken, setOldToken] = useLocalStorage({
    key: 'fcm-old-token',
    defaultValue: '',
  });
  const [allowedRoutes, setAllowedRoutes] = useLocalStorage<string[]>({
    key: ALLOWED_ROUTES,
    defaultValue: [],
  });
  const [disallowedRoutes, setDisallowedRoutes] = useLocalStorage<string[]>({
    key: DISALLOWED_ROUTES,
    defaultValue: [],
  });

  async function getFCMToken() {
    const permission = Notification.permission;
    if (permission === 'granted') {
      const messaging = getMessaging(firebaseApp);
      const token = await getToken(messaging, {
        vapidKey: FIREBASE_VAPID_KEY,
      });

      return token;
    }
    return '';
  }

  async function handleAcceptNotification() {
    const permission = Notification.permission;

    if (permission === 'granted') {
      const token = await getFCMToken();

      sendTokenToServer(token);
      close();
      return;
    }

    requestNotificationPermission();
  }

  async function requestNotificationPermission() {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') handleAcceptNotification();
    close();
  }

  function handleRejectNotification() {
    if (disallowedRoutes.includes(AUTHORITY_CODE || MAIN_ROUTE)) return;
    setDisallowedRoutes([...disallowedRoutes, AUTHORITY_CODE || MAIN_ROUTE]);
  }

  function handleClose() {
    close();
    handleRejectNotification();
  }

  async function sendTokenToServer(token: string) {
    const formData = new FormData();

    if (token === oldToken) {
      formData.append('token', token);
    } else {
      formData.append('token', token);
      formData.append('old_token', oldToken);
    }

    try {
      const { data } = await axios('/authorities/main/set-push-token', {
        method: 'post',
        headers: {
          language: locale,
          code: AUTHORITY_CODE,
        },
        data: formData,
      });

      if (data?.status && data?.codes) {
        setOldToken(token);
        setAllowedRoutes(data?.codes || []);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function getInitialAllowedRoutes() {
    if (JSON.parse(localStorage.getItem(ALLOWED_ROUTES) || '[]')?.length > 0) return;

    try {
      const token = await getFCMToken();
      if (!token) return;

      const { data: initialAllowedRoutes } = await axios.get('/authorities/main/get-push-token-list', {
        headers: { code: 'digital' },
        params: { token },
      });

      if (initialAllowedRoutes?.codes) setAllowedRoutes(initialAllowedRoutes?.codes);
    } catch (e) {
      console.log(e);
    }
  }

  async function activateNotificationModal() {
    const permission = Notification.permission;
    if (permission === 'denied') return;

    if (JSON.parse(localStorage.getItem(DISALLOWED_ROUTES) || '[]').includes(AUTHORITY_CODE || MAIN_ROUTE)) return;
    await getInitialAllowedRoutes();
    if (JSON.parse(localStorage.getItem(ALLOWED_ROUTES) || '[]').includes(AUTHORITY_CODE || MAIN_ROUTE)) return;

    open();
  }

  useEffect(() => {
    const notificationTimeout = setTimeout(activateNotificationModal, 2000);
    return () => clearTimeout(notificationTimeout);
  }, []);

  const { data: authorityTitle } = useSWR(['/authorities/main/get-title']);

  return (
    <Modal.Root opened={opened} onClose={close} zIndex={NAVBAR_Z_INDEX + 2} size="sm">
      <Modal.Overlay opacity="0.6" />
      <Modal.Content py="sm" pos="relative" style={{ overflow: 'visible' }}>
        <Center w={36} h={36} bg="primary" className={classnames.bell_icon} style={{ zIndex: NAVBAR_Z_INDEX + 2 }}>
          <BellIcon />
        </Center>
        <Modal.Header p="sm" pt="lg">
          <Modal.Title w="100%" fz="md" ta="center" fw={500} lh="h1" c="#666">
            {authorityTitle?.title || t('O‘zbekiston Respublikasi Hukumat portali')}
          </Modal.Title>
          <Modal.CloseButton onClick={handleClose} aria-label={t('{text}ni yopish', { text: modalTitle[locale] })} />
        </Modal.Header>
        <Modal.Body px="sm" py="0">
          <Divider />
          <Text maw={400} mx="auto" fz={13} ta="center" py="md">
            {t('Veb-saytga joylanayotgan barcha yangiliklardan xabardor bo‘ling!')}
          </Text>
          <Divider />
          <Flex pt="md" gap="lg" wrap="wrap">
            <Button
              radius="0"
              fz="xs"
              fw={400}
              className="grow"
              leftSection={<CheckIcon />}
              classNames={{ section: clsx(classnames.button__icon, classnames.light, classnames.sm) }}
              onClick={handleAcceptNotification}
            >
              {t('Qabul qilish')}
            </Button>
            <Button
              radius="0"
              fz="xs"
              fw={400}
              bg="#eaedef"
              c="#000"
              className="grow"
              leftSection={<CloseIcon />}
              classNames={{ section: classnames.button__icon }}
              onClick={handleClose}
            >
              {t('Rad etish')}
            </Button>
          </Flex>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
