'use client';

import { PollData, PollQuestion } from '@/lib/types';
import { Box, Flex, Progress, Radio, UnstyledButton } from '@mantine/core';
import axios from 'axios';
import { clsx } from 'clsx';
import { getCookie, setCookie } from 'cookies-next';
import { useLocale, useTranslations } from 'next-intl';
import { FormEvent, useEffect, useState } from 'react';
import classnames from './poll.module.css';

import { ArrowRightIcon, CheckMarkIcon } from '@/icons';
import { AUTHORITY_CODE } from '@/lib/utils';

type Props = {
  question: PollQuestion;
};

type Vote = {
  id: number | null;
  answer_id: number | null;
};

const initialVote = { id: null, answer_id: null };

export function Poll({ question }: Props) {
  const t = useTranslations();
  const locale = useLocale();
  const [answerId, setAnswerId] = useState<string>();
  const [vote, setVote] = useState<Vote>(initialVote);
  const [resultVisible, setResultVisible] = useState(false);

  const handleVotes = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { data: poll } = await axios.get<PollData>('/authorities/vote/get-set-survey', {
        headers: { language: locale, code: AUTHORITY_CODE },
        params: {
          survey_id: question?.id,
          answer_id: answerId,
          user_agent: window.navigator.userAgent,
        },
      });

      if (poll?.data) {
        const currentVote = {
          id: poll?.data?.question?.id,
          answer_id: parseInt(answerId || '0'),
        };

        setVote(currentVote);
        setCookie(`vote_${currentVote?.id}`, currentVote);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const currentVote = getCookie(`vote_${question?.id}`),
      parsedVote = JSON.parse(currentVote || '{}');

    if (parsedVote?.id) setVote(parsedVote);
  }, [question]);

  const voteTitle = t('Ovoz berish');

  return vote?.id || resultVisible ? (
    <Flex direction="column" h="100%" justify="space-between" gap="xl">
      <Box>
        <h3 className="font--md font-semibold text-neutral-600 mb-5">{question?.title}</h3>
        <Flex direction="column" gap="lg">
          {question?.answers?.map((answer) => (
            <Flex direction="column" key={answer?.id}>
              <Flex align="center" gap="sm" pb="sm">
                <h4 className="font text-neutral-500">{answer?.title}</h4>
                {vote?.answer_id === answer?.id ? <CheckMarkIcon className="shrink-0" /> : null}
              </Flex>
              <Progress.Root size="xl">
                <Progress.Section
                  value={parseInt(answer?.count)}
                  color={vote?.answer_id === answer?.id ? '#198754' : 'primary'}
                  animated
                >
                  <Progress.Label>{answer?.count}%</Progress.Label>
                </Progress.Section>
              </Progress.Root>
            </Flex>
          ))}
        </Flex>
      </Box>
      <h4 className="font--sm font-semibold text-neutral-600">
        {t('Barcha ovozlar')}: {question?.all_voting}
      </h4>
      {resultVisible ? (
        <UnstyledButton className={classnames.backToVote__btn} onClick={() => setResultVisible(false)}>
          <ArrowRightIcon /> {voteTitle}
        </UnstyledButton>
      ) : null}
    </Flex>
  ) : (
    <form onSubmit={handleVotes} className="h-full">
      <Flex direction="column" h="100%" justify="space-between" gap="xl">
        <Box>
          <h3 className="font--md font-semibold text-neutral-600 mb-5">{question?.title}</h3>
          <Radio.Group name="answer_id" value={answerId} onChange={setAnswerId}>
            <Flex direction="column">
              {question?.answers?.map((answer) => (
                <Radio
                  key={answer?.id}
                  classNames={{
                    root: classnames.poll__radio,
                    body: classnames.poll__radio__body,
                    label: classnames.poll__label,
                  }}
                  value={answer?.id?.toString()}
                  label={answer?.title}
                />
              ))}
            </Flex>
          </Radio.Group>
        </Box>
        <Flex justify="space-between" gap="md" wrap="wrap">
          <UnstyledButton type="submit" className={clsx(classnames.poll__btn)} disabled={!answerId}>
            {voteTitle}
          </UnstyledButton>
          <UnstyledButton
            className={clsx(classnames.poll__btn, classnames.outline)}
            onClick={() => setResultVisible(true)}
          >
            {t('Natijalar')}
          </UnstyledButton>
        </Flex>
      </Flex>
    </form>
  );
}
