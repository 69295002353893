'use client';

import { ChevronRightCircleIcon } from '@/icons';
import { Target } from '@/lib/types';
import { createRandomId, createURL } from '@/lib/utils';
import { Breadcrumb, CustomLink } from '@/ui';
import { Avatar, Box, Flex, Skeleton, Text } from '@mantine/core';
import { clsx } from 'clsx';
import classnames from './link-box.module.css';

type ContentA = {
  title: string;
  children?: React.ReactNode;
};

type ContentB = {
  title?: string;
  children: React.ReactNode;
};

type Content = ContentA | ContentB;

type Props = Content & {
  url: string;
  target?: Target;
  img?: string;
  className?: string;
  breadcrumb?: string;
  anons?: string;
};

export function LinkBox(props: Props) {
  const {
    title = '',
    children,
    url = '/',
    target = '_self',
    img = '',
    className = '',
    breadcrumb = '',
    anons = '',
  } = props;
  const breadcrumbItems = breadcrumb?.trim()
    ? breadcrumb
        ?.split('/')
        ?.filter((item) => !!item)
        .map((item) => ({ title: item, isCurrent: true }))
    : [];

  return (
    <Box
      component={CustomLink}
      href={createURL(url)}
      target={target}
      className={clsx('flex items-center px-6 py-2 gap-5 h-full basis-20 sm:px-3', classnames.box, className)}
    >
      {img ? (
        <Avatar src={img} alt={title} size="lg" className={classnames.box__img} />
      ) : (
        <span className={classnames.line}></span>
      )}
      <Flex direction="column" justify="center" className="grow" gap="xs">
        {breadcrumb?.trim() ? <Breadcrumb items={breadcrumbItems} insideLink /> : null}
        <Text className="text-md uppercase mr-auto lg:text-sm only-2-lines">{children ? children : title}</Text>
        {anons?.trim() ? (
          <Text fz="xs" className="only-2-lines">
            {anons}
          </Text>
        ) : null}
      </Flex>
      <ChevronRightCircleIcon className={classnames.box__icon} />
    </Box>
  );
}

LinkBox.Loading = function LinkBoxLoading({
  count = 3,
  className = '',
  withImg = false,
}: {
  count?: number;
  className?: string;
  withImg?: boolean;
}) {
  return Array.from({ length: count }, () => (
    <Box
      className={clsx(
        'flex items-center px-6 py-2 gap-5 basis-20',
        classnames.box,
        classnames['box--loading'],
        className
      )}
      key={createRandomId()}
    >
      {withImg ? <Skeleton width="56px" height="56px" circle className="shrink-0" /> : null}
      <Skeleton height="1.8rem" />
      <Skeleton width="38px" height="38px" circle className="shrink-0" />
    </Box>
  ));
};
