import { clsx } from 'clsx';
import classnames from './three-stack.module.css';

type Props = { children: React.ReactNode; className?: string; flexible?: boolean };

export function ThreeStack({ children, className = '', flexible = false }: Props) {
  return (
    <div
      className={clsx(
        'grid grid-cols-3 3xl:grid-cols-2 xl:grid-cols-1 gap-y-10',
        classnames['three-stack'],
        className,
        {
          [classnames.flexible]: flexible,
        }
      )}
    >
      {children}
    </div>
  );
}
