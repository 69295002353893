'use client';

import { DocumentIcon } from '@/icons';
import { createRandomId } from '@/lib/utils';
import { CustomLink, Empty, ScrollAnimation } from '@/ui';
import { InfoContent } from '@/widgets';
import { Box, Flex, Skeleton } from '@mantine/core';
import { clsx } from 'clsx';
import { useLocale, useTranslations } from 'next-intl';
import useSWR from 'swr';
import classnames from './documents.module.css';

type Document = { date: string; title: string; link: string; description: string };

export function Documents() {
  const t = useTranslations();
  const locale = useLocale();

  const { data: documents, isLoading } = useSWR<{ data: Document[] }>(['/main-page/lex-page']);

  const link = `https://lex.uz/${!['qr', 'oz'].includes(locale as string) ? locale : 'uz'}/search/nat?body_id=819`;

  return (
    <InfoContent
      title={t('Hujjatlar')}
      details={{ title: t('Barcha hujjatlar'), link, target: '_blank' }}
      withoutGap
      data-bordered="2"
    >
      {isLoading ? (
        <Document.Loading />
      ) : documents?.data && documents?.data?.length > 0 ? (
        documents?.data?.map((document) => <Document key={document?.link} {...document} />)
      ) : (
        <Empty />
      )}
    </InfoContent>
  );
}

function Document({ title, description, date, link }: Document) {
  return (
    <ScrollAnimation>
      <Box
        component={CustomLink}
        href={link}
        target="_blank"
        className={clsx('flex items-center p-6 gap-5 h-full basis-1/3', classnames.box)}
      >
        <DocumentIcon className="shrink-0" />
        <Flex direction="column" gap="xs">
          <p className="only-1-line">{description}</p>
          <h3 className="only-2-lines">{title}</h3>
          <p>{date}</p>
        </Flex>
      </Box>
    </ScrollAnimation>
  );
}

Document.Loading = function DocLinkLoading() {
  return Array.from({ length: 3 }, () => (
    <Box key={createRandomId()} className={clsx('flex items-center p-6 gap-5 basis-1/3', classnames.box)}>
      <Skeleton width={32} height={32} />
      <Box className="flex flex-col gap-2 w-full">
        <Skeleton width="80%" height="1rem" />
        <Skeleton width="80%" height="1.2rem" />
        <Skeleton width="30%" height="1rem" />
      </Box>
    </Box>
  ));
};
