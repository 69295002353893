'use client';

import { ArrowUpCircleIcon } from '@/icons';
import { useTouchAvailable } from '@/lib/hooks';
import { Affix, Box, Tooltip, Transition } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { clsx } from 'clsx';
import { useTranslations } from 'next-intl';
import { isMobile } from 'react-device-detect';
import classnames from './back-to-top.module.css';

export function BackToTop() {
  const t = useTranslations();
  const [scroll, scrollTo] = useWindowScroll();
  const isTouchAvailable = useTouchAvailable();

  const bottom = isMobile ? 10 : 120;
  const right = isMobile ? 20 : 55;

  return (
    <Affix position={{ bottom, right }}>
      <Transition
        transition="slide-up"
        mounted={scroll.y > 400}
        duration={150}
        exitDuration={150}
        timingFunction="linear"
      >
        {(transitionStyles) => (
          <Box style={transitionStyles}>
            <Tooltip
              label={t('Yuqoriga qaytish')}
              position="top"
              color="primary"
              withArrow={true}
              arrowSize={5}
              maw={220}
              multiline={true}
              className="text-center"
              events={{ hover: true, focus: true, touch: false }}
            >
              <button
                onClick={() => scrollTo({ y: 0 })}
                className={clsx(classnames.button, { [classnames.active]: isTouchAvailable })}
                aria-label={t('Yuqoriga qaytish')}
              >
                <ArrowUpCircleIcon />
              </button>
            </Tooltip>
          </Box>
        )}
      </Transition>
    </Affix>
  );
}
