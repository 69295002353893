import { StringList } from '@/lib/types';

const regionList: { [key: string]: StringList } = {
  oz: {
    andijan: 'Andijon',
    bukhara: 'Buxoro',
    fergana: 'Fargʻona',
    gulistan: 'Guliston',
    jizzakh: 'Jizzax',
    namangan: 'Namangan',
    navoiy: 'Navoiy',
    nukus: 'Nukus',
    nurafshon: 'Nurafshon',
    qarshi: 'Qarshi',
    samarkand: 'Samarqand',
    tashkent: 'Toshkent',
    termez: 'Termiz',
    urgench: 'Urganch',
  },
  uz: {
    andijan: 'Андижон',
    bukhara: 'Бухоро',
    fergana: 'Фарғона',
    gulistan: 'Гулистон',
    jizzakh: 'Жиззах',
    namangan: 'Наманган',
    navoiy: 'Навоий',
    nukus: 'Нукус',
    nurafshon: 'Нурафшон',
    qarshi: 'Қарши',
    samarkand: 'Самарқанд',
    tashkent: 'Тошкент',
    termez: 'Термиз',
    urgench: 'Урганч',
  },
  qr: {
    andijan: 'Андижан',
    bukhara: 'Бухара',
    fergana: 'Ферғана',
    gulistan: 'Гүлистан ',
    jizzakh: 'Жиззах',
    namangan: 'Наманган',
    navoiy: 'Наўайы',
    nukus: 'Нөкис',
    nurafshon: 'Нурафшон',
    qarshi: 'Қарсы ',
    samarkand: 'Самарқанд',
    tashkent: 'Ташкент',
    termez: 'Термиз',
    urgench: 'Урғанч',
  },
  ru: {
    andijan: 'Андижан',
    bukhara: 'Бухара',
    fergana: 'Фергана',
    gulistan: 'Гулистан',
    jizzakh: 'Джиззак',
    namangan: 'Наманган',
    navoiy: 'Навои',
    nukus: 'Нукус',
    nurafshon: 'Нурафшан',
    qarshi: 'Карши',
    samarkand: 'Самарканд',
    tashkent: 'Ташкент',
    termez: 'Термез',
    urgench: 'Ургенч',
  },
  en: {
    andijan: 'Andijan',
    bukhara: 'Bukhara',
    fergana: 'Fergana',
    gulistan: 'Gulistan',
    jizzakh: 'Jizzakh',
    namangan: 'Namangan',
    navoiy: 'Navoi',
    nukus: 'Nukus',
    nurafshon: 'Nurafshon',
    qarshi: 'Karshi',
    samarkand: 'Samarkand',
    tashkent: 'Tashkent',
    termez: 'Termez',
    urgench: 'Urgench',
  },
};

export const useRegionList = (locale: string) => {
  return {
    andijan: regionList[locale].andijan,
    bukhara: regionList[locale].bukhara,
    fergana: regionList[locale].fergana,
    gulistan: regionList[locale].gulistan,
    jizzakh: regionList[locale].jizzakh,
    namangan: regionList[locale].namangan,
    navoiy: regionList[locale].navoiy,
    nukus: regionList[locale].nukus,
    nurafshon: regionList[locale].nurafshon,
    qarshi: regionList[locale].qarshi,
    samarkand: regionList[locale].samarkand,
    tashkent: regionList[locale].tashkent,
    termez: regionList[locale].termez,
    urgench: regionList[locale].urgench,
  } as StringList;
};
