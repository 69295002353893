'use client';

import { ChevronLeftIcon } from '@/icons';
import { capitalize, createRandomId, createURL } from '@/lib/utils';
import { CustomLink } from '@/ui';
import { Anchor, Breadcrumbs } from '@mantine/core';
import { clsx } from 'clsx';
import { useTranslations } from 'next-intl';
import classnames from './breadcrumb.module.css';

type Item = {
  title?: string;
  href?: string;
  isCurrent?: boolean;
};

type Props = { items: Item[]; insideLink?: boolean };

export function Breadcrumb({ items, insideLink = false }: Props) {
  const t = useTranslations();

  return items?.length > 0 ? (
    <Breadcrumbs
      separator={<ChevronLeftIcon className={clsx(classnames.icon, { [classnames.insideLink]: insideLink })} />}
      separatorMargin="0"
      className="flex-wrap gap-x-0 gap-y-2"
    >
      {insideLink ? (
        <span className={clsx(classnames.breadcrumb, classnames.active, { [classnames.insideLink]: insideLink })}>
          {t('Bosh sahifa')}
        </span>
      ) : (
        <Anchor
          component={CustomLink}
          href="/"
          className={clsx(classnames.breadcrumb, classnames.active, { [classnames.insideLink]: insideLink })}
        >
          {t('Bosh sahifa')}
        </Anchor>
      )}
      {items?.map((item) => {
        const title = capitalize(item?.title || '');
        if (!title?.trim()) return null;

        return insideLink ? (
          <span
            key={createRandomId()}
            className={clsx(classnames.breadcrumb, {
              [classnames.active]: !item.isCurrent,
              [classnames.insideLink]: insideLink,
            })}
          >
            {title}
          </span>
        ) : (
          <Anchor
            key={createRandomId()}
            component={CustomLink}
            href={createURL(item.href || '/')}
            className={clsx(classnames.breadcrumb, {
              [classnames.active]: !item.isCurrent,
              [classnames.insideLink]: insideLink,
            })}
          >
            {title}
          </Anchor>
        );
      })}
    </Breadcrumbs>
  ) : null;
}
