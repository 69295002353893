'use client';
import { Alert } from '@mantine/core';
import React, { ReactNode } from 'react';

type ErrorBoundaryProps = {
  fallback?: ReactNode;
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    console.error('ERROR BOUNDARY: ', error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    console.error('ERROR BOUNDARY: ', error, info?.componentStack);
  }

  render() {
    if (this.state.hasError)
      return this.props?.fallback ? (
        this.props.fallback
      ) : process.env.NODE_ENV === 'development' ? (
        <Alert title="Error Boundary" variant="light" color="red">
          Something went wrong
        </Alert>
      ) : null;

    return this.props.children;
  }
}
