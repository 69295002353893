'use client';

import { CloseIcon } from '@/icons';
import { NAVBAR_Z_INDEX } from '@/lib/utils';
import { Body } from '@/ui';
import { Modal, ModalProps } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import classnames from './employee.module.css';

type Props = ModalProps & {
  title: string;
  fullname: string;
  content: string;
};

export default function EmployeeModal(props: Props) {
  const { opened, onClose, title, fullname, content } = props;
  const t = useTranslations();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Modal.Root opened={opened} onClose={onClose} zIndex={NAVBAR_Z_INDEX + 2} size="60rem" fullScreen={isMobile}>
      <Modal.Overlay backgroundOpacity={0.3} />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title className={classnames.modal__title}>
            <span className={classnames.left__line}></span>
            {title}
            <span className={classnames.right__line}></span>
          </Modal.Title>
          <Modal.CloseButton
            icon={<CloseIcon />}
            className={classnames.modal__close}
            aria-label={t('{text}ni yopish', { text: title })}
          />
        </Modal.Header>
        <Modal.Body pt="xl" className={classnames.modal__body}>
          <h3 className={classnames.modal__fullname}>{fullname}</h3>
          <Body content={content} className={classnames.modal__content} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
