'use client';

import { useMonth } from '@/lib/hooks';
import { News } from '@/lib/types';
import { createRandomId, createURL } from '@/lib/utils';
import { CustomLink } from '@/ui';
import { Box, Skeleton, Text } from '@mantine/core';
import { clsx } from 'clsx';
import dayjs from 'dayjs';
import { useLocale } from 'next-intl';
import classnames from './calendar-link.module.css';

type Props = News & {
  children?: React.ReactNode;
  withoutBorder?: boolean;
  className?: string;
};

export function CalendarLink(props: Props) {
  const { withoutBorder = false, title, date, url = '/', children, className = '' } = props;

  const locale = useLocale();
  const month = useMonth(locale, date);

  return (
    <Box
      component={CustomLink}
      href={createURL(url)}
      className={clsx('flex items-center px-6 py-2 gap-8 h-full basis-20', classnames.box, className, {
        [classnames['borderless']]: withoutBorder,
      })}
    >
      <Text className="flex flex-col items-center justify-center">
        <span className={classnames.box__date}>{dayjs(new Date(date)).format('DD')}</span>
        {month ? <span className={classnames.box__day}>{month?.slice(0, 3)}</span> : null}
      </Text>
      <Text className={clsx('only-2-lines', classnames.box__title)}>{children ? children : title}</Text>
    </Box>
  );
}

CalendarLink.Loading = function CalendarLinkLoading({
  count = 5,
  withoutBorder = false,
  className = '',
}: {
  count?: number;
  withoutBorder?: boolean;
  className?: string;
}) {
  return Array.from({ length: count }).map(() => (
    <Box
      key={createRandomId()}
      className={clsx('flex items-center px-6 py-2 gap-8 basis-20', classnames.box, className, {
        [classnames['borderless']]: withoutBorder,
      })}
    >
      <Box className="h-full flex flex-col items-center justify-center gap-1">
        <Skeleton width="3rem" height="3rem" />
      </Box>
      <Box className="w-full flex flex-col gap-1">
        <Skeleton width="100%" height="1.5rem" />
        <Skeleton width="80%" height="1.5rem" />
      </Box>
    </Box>
  ));
};
