'use client';

import { usePrint } from '@/lib/hooks';
import { PageData, PageProps } from '@/lib/types';
import { Body, Breadcrumb, Empty, Section, ZoomImage } from '@/ui';
import { Content } from '@/widgets';
import { Container, Image } from '@mantine/core';
import NextImage from 'next/image';
import { useState } from 'react';

type ExtraContent = React.ReactNode | null;

type Props = {
  page: PageProps & {
    data: PageData;
  };
  beforeBody?: ExtraContent;
  afterBody?: ExtraContent;
  blurredImg?: string;
};

export function PagesView({ page, beforeBody = null, afterBody = null, blurredImg = '' }: Props) {
  const { ref, handlePrint } = usePrint();
  const [imgError, setImgError] = useState(false);

  const breadcrumbItems = [
    { title: page?.breadcrumb, href: `/general-menu/${page?.menu_id}` },
    { title: page?.data?.title, isCurrent: true },
  ];

  return (
    <Container>
      <Section
        rightMenu={{
          isActive: true,
          id: page?.menu_id,
          activeItem: { id: page?.menu_item_id },
        }}
      >
        <Content ref={ref}>
          <Content.Header details={{ handlePrint, ageLimit: page?.data?.age_limit }}>
            <Breadcrumb items={breadcrumbItems} />
          </Content.Header>
          <Content.Title>{page?.data?.title}</Content.Title>
          <Content.Body
            className=""
            isShareable
            views={page?.data?.views}
            updatedAt={!!page?.data?.updated_date ? page?.data?.updated_date : null}
          >
            {beforeBody}
            <ZoomImage>
              {page?.data?.body_image?.trim() && !imgError ? (
                <Image
                  component={NextImage}
                  src={page?.data?.body_image}
                  maw="100%"
                  width={800}
                  height={400}
                  alt={page?.data?.title}
                  placeholder={blurredImg?.trim() ? 'blur' : 'empty'}
                  blurDataURL={blurredImg}
                  onError={() => setImgError(true)}
                />
              ) : null}

              {!page?.data?.body?.trim() && !beforeBody && !afterBody ? <Empty /> : <Body content={page?.data?.body} />}
            </ZoomImage>
            {afterBody}
          </Content.Body>
        </Content>
      </Section>
    </Container>
  );
}
