'use client';

import { NAVBAR_Z_INDEX } from '@/lib/utils';
import { Flex, Loader } from '@mantine/core';

export function SuspenseLoader() {
  return (
    <Flex pos="fixed" inset="0" bg="#fff" justify="center" align="center" style={{ zIndex: NAVBAR_Z_INDEX * 2 }}>
      <Loader size="lg" />
    </Flex>
  );
}
