import { StaticImageData } from 'next/image';
import ListIcon from '/public/img/icons/advice-list.svg';
import ArrowBottomIcon from '/public/img/icons/arrow-bottom.svg';
import ArrowRightIcon from '/public/img/icons/arrow-right.svg';
import ArrowUpCircleIcon from '/public/img/icons/arrow-up-circle.svg';
import AudioIcon from '/public/img/icons/audio.svg';
import BellIcon from '/public/img/icons/bell.svg';
import CheckMarkIcon from '/public/img/icons/check-mark.svg';
import CheckIcon from '/public/img/icons/check.svg';
import ChevronLeftIcon from '/public/img/icons/chevron-arrow-left.svg';
import {
  default as ChevronArrowRightIcon,
  default as ChevronRightCircleIcon,
} from '/public/img/icons/chevron-arrow-right-circle.svg';
import ClockIcon from '/public/img/icons/clock.svg';
import DocumentIcon from '/public/img/icons/document.svg';
import EmptyIcon from '/public/img/icons/empty.svg';
import EnvelopeFilledIcon from '/public/img/icons/envelope-filled.svg';
import EnvelopeIcon from '/public/img/icons/envelope.svg';
import ErrorIcon from '/public/img/icons/error.svg';
import EyeOutlineIcon from '/public/img/icons/eye-outline.svg';
import EyeIcon from '/public/img/icons/eye.svg';
import LoadingIcon from '/public/img/icons/loading.svg';
import LocationIcon from '/public/img/icons/location.svg';
import MessengerIcon from '/public/img/icons/messenger.svg';
import MuteIcon from '/public/img/icons/mute.svg';
import NoImageIcon from '/public/img/icons/no-image.svg';
import PhoneCallIcon from '/public/img/icons/phone-call.svg';
import PhoneIcon from '/public/img/icons/phone.svg';
import PortfolioOutlinedIcon from '/public/img/icons/portfolio-outlined.svg';
import PortfolioIcon from '/public/img/icons/portfolio.svg';
import PrintIcon from '/public/img/icons/print.svg';
import RefreshIcon from '/public/img/icons/refresh.svg';
import ReplyIcon from '/public/img/icons/reply.svg';
import ResetIcon from '/public/img/icons/reset.svg';
import ArrowRightBoldIcon from '/public/img/icons/right-bold-arrow.svg';
import SearchIcon from '/public/img/icons/search.svg';
import ShareIcon from '/public/img/icons/share.svg';
import FacebookIcon from '/public/img/icons/social_media/facebook.svg';
import InstagramIcon from '/public/img/icons/social_media/instagram.svg';
import LinkedinIcon from '/public/img/icons/social_media/linkedin.svg';
import TelegramIcon from '/public/img/icons/social_media/telegram.svg';
import TwitterIcon from '/public/img/icons/social_media/twitter.svg';
import YoutubeIcon from '/public/img/icons/social_media/youtube.svg';
import SuccessIcon from '/public/img/icons/success.svg';
import BlackWhiteThemeIcon from '/public/img/icons/sun-black-white.svg';
import ContrastThemeIcon from '/public/img/icons/sun-contrast.svg';
import DefaultThemeIcon from '/public/img/icons/sun-default.svg';
import CloseIcon from '/public/img/icons/times.svg';
import ToDetailsIcon from '/public/img/icons/to-details.svg';
import WarningIcon from '/public/img/icons/warning.svg';
import WebsiteIcon from '/public/img/icons/website.svg';
import clear from '/public/img/weather/clear.png';
import fog from '/public/img/weather/fog.png';
import heavy_rain from '/public/img/weather/heavy_rain.png';
import heavy_sleet from '/public/img/weather/heavy_sleet.png';
import heavy_snow from '/public/img/weather/heavy_snow.png';
import light_rain from '/public/img/weather/light_rain.png';
import light_sleet from '/public/img/weather/light_sleet.png';
import light_snow from '/public/img/weather/light_snow.png';
import mostly_clear from '/public/img/weather/mostly_clear.png';
import mostly_cloudy from '/public/img/weather/mostly_cloudy.png';
import overcast from '/public/img/weather/overcast.png';
import partly_cloudy from '/public/img/weather/partly_cloudy.png';
import rain from '/public/img/weather/rain.png';
import sleet from '/public/img/weather/sleet.png';
import snow from '/public/img/weather/snow.png';
import thunderstorm from '/public/img/weather/thunderstorm.png';

const WEATHER_ICONS: { [key: string]: StaticImageData } = {
  clear,
  fog,
  heavy_rain,
  heavy_sleet,
  heavy_snow,
  light_rain,
  light_sleet,
  light_snow,
  mostly_clear,
  mostly_cloudy,
  overcast,
  partly_cloudy,
  rain,
  sleet,
  snow,
  thunderstorm,
};

export {
  ArrowBottomIcon,
  ArrowRightBoldIcon,
  ArrowRightIcon,
  ArrowUpCircleIcon,
  AudioIcon,
  BellIcon,
  BlackWhiteThemeIcon,
  CheckIcon,
  CheckMarkIcon,
  ChevronArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightCircleIcon,
  ClockIcon,
  CloseIcon,
  ContrastThemeIcon,
  DefaultThemeIcon,
  DocumentIcon,
  EmptyIcon,
  EnvelopeFilledIcon,
  EnvelopeIcon,
  ErrorIcon,
  EyeIcon,
  EyeOutlineIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  ListIcon,
  LoadingIcon,
  LocationIcon,
  MessengerIcon,
  MuteIcon,
  NoImageIcon,
  PhoneCallIcon,
  PhoneIcon,
  PortfolioIcon,
  PortfolioOutlinedIcon,
  PrintIcon,
  RefreshIcon,
  ReplyIcon,
  ResetIcon,
  SearchIcon,
  ShareIcon,
  SuccessIcon,
  TelegramIcon,
  ToDetailsIcon,
  TwitterIcon,
  WarningIcon,
  WEATHER_ICONS,
  WebsiteIcon,
  YoutubeIcon,
};
