'use client';

import { Anchor } from '@mantine/core';
import { useTranslations } from 'next-intl';
import classnames from './skip-to-main-content.module.css';

export function SkipToMainContent() {
  const t = useTranslations();

  return (
    <Anchor href="#main-content" className={classnames.link}>
      {t('asosiy-kontentga-o-tish')}
    </Anchor>
  );
}
