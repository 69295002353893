'use client';

import { ErrorBoundary } from '@/components/global';
import { usePathname } from '@/i18n/routing';
import { ArrowBottomIcon, ChevronLeftIcon } from '@/icons';
import { useHeaderStore } from '@/lib/store';
import { RightMenuActiveItem, RightMenu as RightMenuT, Target } from '@/lib/types';
import { createURL } from '@/lib/utils';
import { CustomLink } from '@/ui';
import { Box, Button, Collapse, Flex, Skeleton } from '@mantine/core';
import { useDisclosure, useIntersection, useMediaQuery } from '@mantine/hooks';
import { clsx } from 'clsx';
import { CSSProperties, forwardRef, useEffect, useRef } from 'react';
import useSWR from 'swr';
import classnames from './right-menu.module.css';

type MenuItem = {
  id: number;
  title: string;
  url: string;
  target: Target;
};

type WidgetProps = {
  data: MenuItem[];
  activeItem: RightMenuActiveItem;
  dynamicStyles: CSSProperties;
  isMiniDevice: boolean;
};

type ItemProps = MenuItem & { isActive?: boolean };

export function RightMenu({ id, activeItem, options }: RightMenuT) {
  const headerHeight = useHeaderStore((store) => store.height);
  const isMiniDevice = useMediaQuery('(max-width: 992px)', false, {
    getInitialValueInEffect: false,
  });

  const { data, isLoading } = useSWR<MenuItem[]>(
    options || ['/authorities/lists/get-right-menu', { params: { menu_id: id } }]
  );

  const dynamicStyles: CSSProperties = !isMiniDevice
    ? {
        top: `calc(${headerHeight + 20}px + var(--gap))`, // 20 = marquee warning height
        maxHeight: `calc(100vh - (${headerHeight + 20}px + var(--gap-md)))`, // 20 = marquee warning height
      }
    : {};

  return (
    <ErrorBoundary>
      {isLoading ? (
        <Box className={classnames.menu} style={dynamicStyles}>
          <Skeleton width="100%" className={classnames.menu__skeleton} />
        </Box>
      ) : data && data?.length > 0 ? (
        <Widget
          data={data}
          activeItem={activeItem}
          dynamicStyles={dynamicStyles}
          isMiniDevice={isMiniDevice || false}
        />
      ) : null}
    </ErrorBoundary>
  );
}

function Widget(props: WidgetProps) {
  const { data, activeItem = { title: '' }, dynamicStyles, isMiniDevice } = props;

  const [opened, { toggle }] = useDisclosure(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 1,
  });

  useEffect(() => {
    const target = entry?.target as HTMLElement;
    if (!target || isMiniDevice) return;

    if (!entry?.isIntersecting) {
      containerRef?.current?.scrollTo({ top: target?.offsetTop, behavior: 'smooth' });
    }
  }, [entry?.target]);

  return (
    <Flex direction="column" gap="md" ref={containerRef} className={classnames.menu} style={dynamicStyles}>
      {isMiniDevice ? (
        <Button
          fullWidth
          onClick={toggle}
          size="lg"
          radius="0"
          classNames={{ root: classnames.menu__btn, label: classnames.menu__btn__label }}
          justify="space-between"
          rightSection={<ArrowBottomIcon className={clsx(classnames.btn__icon, { [classnames.active]: opened })} />}
        >
          {data?.find((item) => item.id === activeItem?.id)?.title || activeItem?.title || 'N/A'}
        </Button>
      ) : null}

      <Collapse in={isMiniDevice ? opened : true}>
        <Flex direction="column">
          {data
            ?.filter((item) => item?.title?.trim())
            ?.map((item) =>
              item?.id === activeItem?.id ? (
                <Item key={item?.id} isActive {...item} ref={ref} />
              ) : (
                <Item key={item?.id} {...item} />
              )
            )}
        </Flex>
      </Collapse>
    </Flex>
  );
}

const Item = forwardRef<HTMLAnchorElement | null, ItemProps>(function Item(props, ref) {
  const { url, title, isActive = false, target = '_self' } = props;
  const pathname = usePathname();

  return (
    <CustomLink
      href={createURL(url)}
      target={target}
      className={clsx(classnames.menu__item, {
        [classnames.active]: isActive,
        [classnames.disabled]: pathname === createURL(url),
      })}
      ref={ref}
    >
      <span>
        <ChevronLeftIcon />
      </span>
      {title}
    </CustomLink>
  );
});
