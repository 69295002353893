import { BlackWhiteThemeIcon, ContrastThemeIcon, DefaultThemeIcon, NoImageIcon } from '@/icons';
import { ThemeCode, useAccessibilityStore } from '@/lib/store';
import { Flex, Tooltip, UnstyledButton } from '@mantine/core';
import { clsx } from 'clsx';
import { useTranslations } from 'next-intl';
import { ReactElement, useEffect } from 'react';
import classnames from './accessibility.module.css';

type Theme = {
  code: ThemeCode;
  title: string;
  icon: ReactElement<any>;
};

const themes: Theme[] = [
  {
    code: 'default',
    title: 'Oddiy ko‘rinish',
    icon: <DefaultThemeIcon />,
  },
  {
    code: 'contrast',
    title: 'Yuqori kontrastli ko‘rinish',
    icon: <ContrastThemeIcon />,
  },
  {
    code: 'blackwhite',
    title: 'Rangsiz ko‘rinish',
    icon: <BlackWhiteThemeIcon />,
  },
];

export default function Themes() {
  const t = useTranslations();
  const { theme, haveImage, setTheme, setHaveImage } = useAccessibilityStore((store) => store);

  useEffect(() => {
    const root = document.documentElement;

    root?.setAttribute('data-theme', theme);
    if (haveImage) root?.classList.remove('noImage');
    else root?.classList.add('noImage');
  }, [theme, haveImage]);

  return (
    <Flex pt="sm" align="center" gap="lg">
      {themes.map((th) => (
        <Tooltip
          key={th.code}
          withinPortal={false}
          label={t(th.title)}
          events={{ hover: true, focus: true, touch: false }}
          withArrow
          arrowSize={5}
          offset={5}
          maw={180}
          multiline={true}
          color="primary"
          position="bottom"
          className="text-center"
        >
          <UnstyledButton
            className={clsx(classnames.theme__box, { [classnames.active]: theme === th.code })}
            onClick={() => setTheme(th.code)}
            aria-label={t(th.title)}
          >
            {th.icon}
          </UnstyledButton>
        </Tooltip>
      ))}
      <Tooltip
        withinPortal={false}
        label={t('Rasmsiz ko‘rinish')}
        events={{ hover: true, focus: true, touch: false }}
        withArrow
        arrowSize={5}
        offset={5}
        maw={180}
        multiline={true}
        color="primary"
        position="bottom"
        className="text-center"
      >
        <UnstyledButton
          className={clsx(classnames.theme__box, { [classnames.active]: !haveImage })}
          onClick={() => setHaveImage(!haveImage)}
          aria-label={t('Rasmsiz ko‘rinish')}
        >
          <NoImageIcon />
        </UnstyledButton>
      </Tooltip>
    </Flex>
  );
}
