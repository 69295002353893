'use client';

import { Breakpoints, MasonryOptions } from '@/lib/types';
import { useViewportSize } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import classnames from './masonry.module.css';

type Props = {
  columns: MasonryOptions;
  children: React.ReactNode[];
};

const breakpoints: { [key in Breakpoints]: number } = {
  '4xl': 1600,
  '3xl': 1400,
  '2xl': 1200,
  xl: 992,
  lg: 768,
  md: 600,
  sm: 520,
  xsm: 420,
};

export function Masonry(props: Props) {
  const { columns, children } = props;
  // eslint-disable-next-line
  const { width } = useViewportSize();
  // eslint-disable-next-line
  const [columnsCount, setColumnsCount] = useState(columns.default);

  const columnWrapper: {
    [key: string]: React.ReactNode[];
  } = {};
  const result = [];

  // create columns
  for (let i = 0; i < columnsCount; i++) {
    columnWrapper[`column${i}`] = [];
  }

  // divide children into columns
  for (let i = 0; i < children.length; i++) {
    const columnIndex = i % columnsCount;
    columnWrapper[`column${columnIndex}`].push(
      <div className={classnames.child} key={i}>
        {children[i]}
      </div>
    );
  }

  // wrap children in each column with a div
  for (let i = 0; i < columnsCount; i++) {
    result.push(
      <div className={classnames.column} key={i}>
        {columnWrapper[`column${i}`]}
      </div>
    );
  }

  function handleColumnsCount() {
    if (columns?.breakpoints) {
      const matchedBreakpoint = Object.keys(columns.breakpoints)?.find(
        (breakpoint) => width > breakpoints[breakpoint as Breakpoints]
      );

      if (matchedBreakpoint) {
        setColumnsCount(columns.breakpoints[matchedBreakpoint as Breakpoints] || columns.default);
      } else {
        setColumnsCount(columns.default);
      }
    }
  }

  useEffect(() => {
    handleColumnsCount();
  }, [width]);

  return <div className={classnames.container}>{result}</div>;
}
