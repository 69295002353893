'use client';

import { usePathname } from '@/i18n/routing';
import { Language } from '@/lib/types';
import { NAVBAR_Z_INDEX } from '@/lib/utils';
import { Button, Menu } from '@mantine/core';
import { useTranslations } from 'next-intl';
import classnames from './language-switcher.module.css';

type Props = {
  locale: string;
  locales: Language[];
  isMobile?: boolean;
};

const formatTitle = (lang: Language | undefined) => {
  if (!lang) return 'N/A';
  return lang?.title?.slice(0, lang.code === 'oz' ? 3 : 2);
};

export function LanguageSwitcher(props: Props) {
  const { locale, locales } = props;
  const pathname = usePathname();
  const t = useTranslations();
  const currentLocale = locales?.find((l) => l.code === locale);

  return (
    <Menu shadow="md" zIndex={NAVBAR_Z_INDEX + 1} menuItemTabIndex={0} radius={5}>
      <Menu.Target>
        <Button
          variant="outline"
          color="primary"
          classNames={{
            root: classnames.root,
            label: classnames.label,
          }}
          aria-label={t('Tilni tanlash')}
        >
          {formatTitle(currentLocale)}
        </Button>
      </Menu.Target>
      <Menu.Dropdown px="xs" py="sm">
        {locales
          ?.filter((l) => l.code !== currentLocale?.code)
          ?.map((l) => (
            <Menu.Item component="a" href={`/${l.code}${pathname}`} className={classnames.menu__item} key={l.code}>
              {l?.title}
            </Menu.Item>
          ))}
      </Menu.Dropdown>
    </Menu>
  );
}
