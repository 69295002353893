'use client';

import { ArrowBottomIcon } from '@/icons';
import { GOOGLE_TRANSLATE_LANGUAGES } from '@/lib/utils';
import { Flex, Select } from '@mantine/core';
import { deleteCookie, getCookie, hasCookie, setCookie } from 'cookies-next';
import { useTranslations } from 'next-intl';
import Script from 'next/script';
import { memo, useEffect, useState } from 'react';
import classnames from './accessibility.module.css';

declare global {
  var google: any;
  var googleTranslateElementInit: any;
}

const COOKIE_NAME = 'googtrans';

export const GoogleTranslate = memo(function GoogleTranslate() {
  const t = useTranslations();
  const DEFAULT_VALUE = { label: t('Tilni tanlang'), value: '' };
  const [currentLang, setCurrentLang] = useState(DEFAULT_VALUE);

  function initializeGoogleTranslate() {
    if (window.google) new window.google.translate.TranslateElement();
  }

  function setGoogleTranslateCookie(value: string | null) {
    if (!hasCookie(COOKIE_NAME) && !value?.trim()) return;

    if (!value?.trim()) {
      deleteCookie(COOKIE_NAME);
      window.location.reload();
      return;
    }

    setCookie(COOKIE_NAME, `/auto/${value}`);
    window.location.reload();
  }

  useEffect(() => {
    const cookie = getCookie(COOKIE_NAME);

    if (cookie && cookie?.trim()) {
      const splitted = cookie?.split('/');
      const currentCookie = splitted[splitted.length - 1];
      const lang = GOOGLE_TRANSLATE_LANGUAGES.find((lng) => lng.value === currentCookie) || DEFAULT_VALUE;

      setCurrentLang(lang);
      window.googleTranslateElementInit = initializeGoogleTranslate;
    } else setCurrentLang(DEFAULT_VALUE);
  }, [getCookie(COOKIE_NAME)]);

  return (
    <>
      <Script
        src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        defer
        strategy="lazyOnload"
        id="google-translate"
      />
      <Flex direction="column" gap="md">
        <h3 className={classnames.title}>{t('Google tarjimon')}:</h3>
        <div translate="no">
          <Select
            data={[DEFAULT_VALUE, ...GOOGLE_TRANSLATE_LANGUAGES]}
            value={currentLang.value}
            onChange={(value) => setGoogleTranslateCookie(value)}
            allowDeselect={false}
            searchable
            maxDropdownHeight={200}
            comboboxProps={{ withinPortal: false }}
            rightSectionPointerEvents="none"
            rightSection={<ArrowBottomIcon />}
            radius="md"
            classNames={{ input: classnames.google__translate__input }}
            aria-label={t('Google tarjimon')}
            nothingFoundMessage={t("Ma'lumot topilmadi")}
          />
        </div>
      </Flex>
    </>
  );
});
