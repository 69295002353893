'use client';

import { ArrowRightIcon, ToDetailsIcon } from '@/icons';
import { DetailsLink as DetailsLinkT } from '@/lib/types';
import { CustomLink } from '@/ui';
import classnames from './details-link.module.css';

export function DetailsLink({ link = '/', title = '', target = '_self' }: DetailsLinkT) {
  return (
    <CustomLink href={link || '/'} target={target} className={classnames.link}>
      {title}
      <span>{target === '_blank' ? <ToDetailsIcon /> : <ArrowRightIcon />}</span>
    </CustomLink>
  );
}
