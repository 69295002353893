'use client';

import { removeLastSlash } from '@/lib/utils';
import { useLocale } from 'next-intl';
import { AppProgressBar } from 'next-nprogress-bar';

const comparePathname = (locale: string, url: URL) => {
  const pathname = url.pathname.startsWith(`/${locale}`) ? url.pathname : `/${locale}${url.pathname}`;
  return Object.assign(url, { pathname: removeLastSlash(pathname) });
};

export function ProgressBar() {
  const locale = useLocale();

  return (
    <AppProgressBar
      color="#2299dd"
      height="4px"
      options={{ showSpinner: false }}
      shallowRouting
      shouldCompareComplexProps={false}
      targetPreprocessor={(url) => comparePathname(locale, url)}
    />
  );
}
