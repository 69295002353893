'use client';

import { usePathname, useRouter } from '@/i18n/routing';
import { ArrowBottomIcon, CloseIcon, SearchIcon } from '@/icons';
import { useQueryString } from '@/lib/hooks';
import { NewsQueryParams } from '@/lib/types';
import { capitalize, sanitizeInput } from '@/lib/utils';
import { Button, ComboboxItem, Flex, Select, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import classnames from './news.module.css';

type Props = {
  params: NewsQueryParams;
  setParams: (params: NewsQueryParams) => void;
  options: ComboboxItem[];
};

type FormValues = {
  text: string;
  start_date: Date | null;
  end_date: Date | null;
  activity_id: string | null;
};

const TODAY = new Date();

export function NewsForm({ options, params, setParams }: Props) {
  const t = useTranslations();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const createQueryString = useQueryString(searchParams.toString());

  const { onSubmit, getInputProps, setFieldValue, values } = useForm<FormValues>({
    initialValues: {
      text: searchParams.get('text') || '',
      start_date: searchParams.get('start_date') ? new Date(searchParams.get('start_date') as string) : null,
      end_date: searchParams.get('end_date') ? new Date(searchParams.get('end_date') as string) : null,
      activity_id: searchParams.get('activity_id') || null,
    },
  });

  const handleSubmit = async (formValues: FormValues) => {
    const values = {
      text: sanitizeInput(formValues?.text),
      start_date: formValues.start_date ? dayjs(formValues.start_date).format('YYYY-MM-DD') : null,
      end_date: formValues.end_date ? dayjs(formValues.end_date).format('YYYY-MM-DD') : null,
      activity_id: formValues?.activity_id,
    };

    if (JSON.stringify(params) === JSON.stringify(values)) return;

    setParams(values);

    const PATH = `${pathname}?${createQueryString({ ...values, page: null })}`;
    router.replace(PATH, { scroll: false });
  };

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Flex gap="lg" align="flex-end" wrap="wrap">
        <TextInput
          {...getInputProps('text')}
          classNames={classnames}
          label={t('Kalit so‘zlar')}
          placeholder={t('Kalit so‘zlar')}
        />
        <DateInput
          {...getInputProps('start_date')}
          classNames={{ ...classnames, root: classnames.root__datepicker }}
          clearable
          valueFormat="YYYY-MM-DD"
          maxDate={values?.end_date || TODAY}
          label={capitalize(t('bo‘lgan davr dan'))}
          placeholder={capitalize(t('bo‘lgan davr dan'))}
        />
        <DateInput
          {...getInputProps('end_date')}
          classNames={{ ...classnames, root: classnames.root__datepicker }}
          clearable
          valueFormat="YYYY-MM-DD"
          minDate={values?.start_date || undefined}
          maxDate={TODAY}
          label={capitalize(t('davr gacha'))}
          placeholder={capitalize(t('davr gacha'))}
        />
        <Select
          {...getInputProps('activity_id')}
          classNames={{ ...classnames, input: classnames.select }}
          searchable
          clearable
          label={t('Yo‘nalish')}
          placeholder={t('Yo‘nalish')}
          nothingFoundMessage={t("Ma'lumot topilmadi")}
          clearButtonProps={{
            'aria-label': t('{text}ni yopish', { text: t('Yo‘nalish') }),
          }}
          data={options}
          rightSection={values.activity_id ? <CloseIcon /> : <ArrowBottomIcon />}
          rightSectionPointerEvents={values.activity_id ? 'all' : 'none'}
          rightSectionProps={{
            className: classnames.select__icon,
            onClick: () => setFieldValue('activity_id', null),
          }}
        />
        <Button
          type="submit"
          bg="primary"
          size="md"
          radius="0"
          classNames={{ root: classnames.submit__btn }}
          aria-label={t('Qidiruv')}
        >
          <SearchIcon />
        </Button>
      </Flex>
    </form>
  );
}
