import { useEffect, useState } from 'react';

export const useTouchAvailable = () => {
  const [isTouchAvailable, setIsTouchAvailable] = useState(false);

  useEffect(() => {
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
      setIsTouchAvailable(true);
    }
  }, []);

  return isTouchAvailable;
};
