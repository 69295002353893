import { useCallback } from 'react';

type QueryParams = Record<string, string | null>;

export const useQueryString = (initialParams = '') => {
  const createQueryString = useCallback(
    (newParams: QueryParams) => {
      const params = new URLSearchParams(initialParams);

      Object.entries(newParams).forEach(([key, value]) => {
        if (!value) {
          params.delete(key);
        } else {
          params.set(key, value);
        }
      });

      return params.toString();
    },
    [initialParams]
  );

  return createQueryString;
};
