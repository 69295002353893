import { useAccessibilityStore } from '@/lib/store';
import { Checkbox, Flex } from '@mantine/core';
import { useMouse } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import { createPortal } from 'react-dom';
import classnames from '../accessibility.module.css';

export default function Concentrate() {
  const t = useTranslations();
  const { concentrate, setConcentrate } = useAccessibilityStore((store) => store);

  return (
    <Flex direction="column" gap="md">
      <h3 className={classnames.title}>{t('diqqatni-jamlash')}:</h3>
      <Checkbox
        checked={concentrate}
        onChange={(event) => setConcentrate(event.currentTarget.checked)}
        label={t('Yoqish / O‘chirish')}
        aria-label={`${t('diqqatni-jamlash')}: ${t('Yoqish / O‘chirish')}`}
      />
      {concentrate ? createPortal(<ConcentratedField />, document.body) : null}
    </Flex>
  );
}

const ConcentratedField = () => {
  const { y } = useMouse();
  return <div className={classnames.concentrated_field} style={{ top: y + 'px' }} />;
};
