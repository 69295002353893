'use client';

import { FacebookIcon, InstagramIcon, LinkedinIcon, TelegramIcon, TwitterIcon, YoutubeIcon } from '@/icons';
import { SocialMedia } from '@/lib/types';
import { CustomLink } from '@/ui';
import { FloatingPosition, Tooltip } from '@mantine/core';
import { clsx } from 'clsx';
import classnames from './social-media-icon.module.css';

type Props = {
  type: SocialMedia;
  url: string;
  circle?: boolean;
  variant?: 'primary' | 'secondary';
  position?: FloatingPosition;
};

const icons = {
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  telegram: <TelegramIcon />,
  youtube: <YoutubeIcon />,
  twitter: <TwitterIcon />,
  linkedin: <LinkedinIcon />,
};

const variants = {
  primary: '#043b87',
  secondary: '#bcbcbc',
};

export function SocialMediaIcon(props: Props) {
  const { type = 'facebook', url = '/', circle = false, variant = 'primary', position = 'top' } = props;

  return (
    <Tooltip
      label={type}
      withinPortal={false}
      events={{ hover: true, focus: true, touch: false }}
      position={position}
      withArrow
      arrowSize={5}
      offset={5}
      openDelay={300}
      className={classnames.tooltip}
      color={circle ? variants.primary : variants[variant]}
    >
      <CustomLink
        href={url || '/'}
        target="_blank"
        className={clsx(classnames['social-media'], classnames[variant], { [classnames.circle]: circle })}
        aria-label={type}
      >
        {icons[type?.toLowerCase() as SocialMedia]}
      </CustomLink>
    </Tooltip>
  );
}
