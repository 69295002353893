'use client';

import { EmptyIcon } from '@/icons';
import { Flex } from '@mantine/core';
import { useTranslations } from 'next-intl';

export function Empty({ className = '' }: { className?: string }) {
  const t = useTranslations();

  return (
    <Flex direction="column" gap="xs" justify="center" align="center" mih="25rem" className={className}>
      <EmptyIcon />
      <p className="font-sm">{t('Maʼlumot yoʻq')}</p>
    </Flex>
  );
}
