import { RightMenu as RightMenuT, Target } from '@/lib/types';
import { DetailsLink, Title } from '@/ui';
import { RightMenu } from '@/widgets';
import { Box } from '@mantine/core';
import { clsx } from 'clsx';
import classnames from './section.module.css';

type Props = {
  children: React.ReactNode;
  title?: string | null;
  details?: { link: string; title: string; target?: Target } | null;
  rightMenu?: RightMenuT;
  withShadow?: boolean;
  className?: string;
};

export function Section(props: Props) {
  const {
    children,
    title = null,
    details = null,
    rightMenu = { isActive: false },
    withShadow = false,
    className = '',
  } = props;

  return (
    <section className={clsx('py-5 3xl:py-3', { [classnames.shadow]: withShadow }, className)}>
      {title ? <Title text={title} className="pb-6" /> : null}
      {rightMenu?.isActive ? (
        <Box className={classnames.withRightMenu}>
          <Box className="shrink xl:order-2">{children}</Box>
          <RightMenu {...rightMenu} />
        </Box>
      ) : (
        children
      )}
      {details && (
        <div className="block text-center pt-5">
          <DetailsLink link={details?.link} title={details?.title} target={details?.target || '_self'} />
        </div>
      )}
    </section>
  );
}
