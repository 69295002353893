'use client';

import Script from 'next/script';
import { YMInitializer } from 'react-yandex-metrika';

export function Scripts() {
  return (
    <>
      <Script src="/scripts/www-counter.js" async strategy="lazyOnload" id="www-counter" />
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-XESZL19D7L" async strategy="lazyOnload" />
      <YMInitializer
        accounts={[96657348]}
        options={{
          defer: true,
          webvisor: true,
          clickMap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          ecommerce: 'dataLayer',
        }}
      />
    </>
  );
}
