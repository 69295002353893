'use client';

import { Box, Flex } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import sanitize from 'sanitize-html';
const LottieAnimation = dynamic(() => import('./LottieAnimation'), {
  loading: () => <Box w={20} h={400}></Box>,
});

export function NotFound() {
  const t = useTranslations();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Flex direction="column" justify="center" align="center" gap="sm" pb="3rem" px="sm">
      <LottieAnimation />
      <p
        dangerouslySetInnerHTML={{
          __html: sanitize(
            t(
              'kechirasiz-biz-siz-qidirayotgan-sahifani-topa-olmadik-afsuski-siz-izlagan-sahifa-mavjud-emas-eng-yaxshisi-bosh-sahifamizga-qaytib-navigatsiya-va-qidiruvdan-foydalanish-yoki-url-manzilini-kiritishda-xatolik-borligini-tekshirgan-ma-qul-sizga-katta-rahmat-tashrifingizdan-juda-xursandmiz'
            )
          ),
        }}
        className={`max-w-3xl text-center text-blue-900 ${isMobile ? 'font--sm' : 'font'}`}
      ></p>
    </Flex>
  );
}
