'use client';

import { Box, BoxComponentProps, Flex } from '@mantine/core';

import { DetailsLink as DetailsLinkT } from '@/lib/types';
import { DetailsLink, Title } from '@/ui';

type Props = {
  title: string;
  details: DetailsLinkT;
  children: React.ReactNode;
  withoutGap?: boolean;
} & BoxComponentProps;

export function InfoContent({
  title,
  details = { link: '/', title: 'N/A', target: '_self' },
  children,
  withoutGap = false,
  className = '',
  ...props
}: Props) {
  return (
    <Box className="flex flex-col" {...props}>
      <Flex justify="space-between" align="center" wrap="wrap" className="pb-5 gap-2">
        <Title text={title} />
        <DetailsLink title={details?.title} link={details?.link} target={details?.target} />
      </Flex>
      <Flex direction="column" className={`${withoutGap ? 'h-full' : 'gap-4'} ${className}`}>
        {children}
      </Flex>
    </Box>
  );
}
