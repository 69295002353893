'use client';

import { RefreshIcon } from '@/icons';
import { StringList } from '@/lib/types';
import { Button, Flex, Image, Input, InputProps, Loader } from '@mantine/core';
import { useTranslations } from 'next-intl';

type Props = {
  isLoading: boolean;
  captchaImg: string;
  label?: string;
  placeholder?: string;
  inputProps: InputProps;
  error: string;
  reset: () => void;
  styles: StringList;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export function Captcha(props: Props) {
  const { isLoading, captchaImg, label = '', placeholder = '', inputProps, error, reset, styles, size = 'md' } = props;
  const t = useTranslations();

  return (
    <Input.Wrapper classNames={styles} label={label} withAsterisk>
      <Flex gap="md" align="center" wrap="wrap">
        {!captchaImg || isLoading ? (
          <Loader size="sm" />
        ) : (
          <Image src={captchaImg} w="auto" h={27} alt="captcha" className="captcha" />
        )}
        <Input
          classNames={styles}
          placeholder={placeholder}
          size={size}
          aria-label={t('Spamdan himoya')}
          {...inputProps}
        />
        <Button bg="gray" onClick={reset} aria-label={t('{text}ni yangilash', { text: t('Spamdan himoya') })}>
          <RefreshIcon />
        </Button>
      </Flex>
      <Input.Error classNames={styles} mt="xs">
        {error}
      </Input.Error>
    </Input.Wrapper>
  );
}
