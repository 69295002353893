'use client';

import { useMonth } from '@/lib/hooks';
import { News } from '@/lib/types';
import { createRandomId, createURL } from '@/lib/utils';
import { CustomLink, Views } from '@/ui';
import { Box, Flex, Overlay, Skeleton } from '@mantine/core';
import { clsx } from 'clsx';
import dayjs from 'dayjs';
import { useLocale } from 'next-intl';
import Image from 'next/image';
import classnames from './news-card.module.css';

type Type = 'default' | 'authority' | 'news';

type Props = News & { type?: Type };

const blurData =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAIAAAA7ljmRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAMklEQVR4nAEnANj/AOrq69bX09fX0urq6wD///8FFwAFDwD9+/0A9PPznKKgnZ+b9PP0ClEaC4KyWcwAAAAASUVORK5CYII=';

export function NewsCard(props: Props) {
  const {
    title,
    anons,
    anons_image = '/img/news_default_bg.jpg',
    date,
    url = '/',
    category_title,
    category_code,
    views,
    type = 'default',
  } = props;

  const locale = useLocale();
  const month = useMonth(locale, date);

  const newsURL = createURL(url);
  const shouldRenderAnons = type === 'default' && anons;
  const hasImg = anons_image?.trim() && anons_image?.includes('http');

  return (
    <article className={classnames.news}>
      <CustomLink href={newsURL} title={title} className={classnames.news__img}>
        <Image
          src={hasImg ? anons_image : '/img/news_default_bg.jpg'}
          alt={title}
          width={500}
          height={260}
          priority
          className={clsx('w-full h-full object-cover', { [classnames.default__img]: !hasImg })}
          placeholder="blur"
          blurDataURL={blurData}
        />
        <Overlay color="#000" backgroundOpacity={0.4} zIndex={0} />
      </CustomLink>
      <Box className={clsx('py-4 px-6 flex flex-col gap-2', classnames.news__content)}>
        <Flex align="center" gap="sm">
          {type === 'default' ? (
            <h3 className={classnames.news__date}>
              <b>{dayjs(new Date(date)).format('DD')}</b>
              {month ? (
                <span className="uppercase">
                  <b>{month}</b>
                </span>
              ) : null}
            </h3>
          ) : null}
          <CustomLink href={newsURL} className={clsx('only-2-lines', classnames.news__title)}>
            {title}
          </CustomLink>
        </Flex>
        {shouldRenderAnons ? (
          <CustomLink href={newsURL} className={clsx('only-2-lines', classnames.news__anons)}>
            {anons}
          </CustomLink>
        ) : null}

        <Flex justify="space-between" className={classnames.news__footer}>
          <p>{dayjs(new Date(date)).format('DD.MM.YYYY')}</p>
          {type === 'default' ? (
            <CustomLink href={`/news/${category_code}`}>{category_title}</CustomLink>
          ) : (
            <Views variant="secondary" count={views || null} />
          )}
        </Flex>
      </Box>
    </article>
  );
}

NewsCard.Loading = function NewsCardLoading({ count = 2 }: { count?: number }) {
  return Array.from({ length: count }).map(() => (
    <article className={classnames.news} key={createRandomId()}>
      <div className={classnames.news__img}>
        <Skeleton width="100%" height="100%" radius={0} />
      </div>
      <Box className={clsx('px-6 py-4 flex flex-col gap-3', classnames.news__content)}>
        <Flex align="center" gap="sm" className="pb-1">
          <Skeleton width="10%" height="2rem" />
          <Skeleton width="90%" height="2rem" />
        </Flex>
        <Flex gap="5px" direction="column">
          <Skeleton width="100%" height="1rem" />
          <Skeleton width="80%" height="1rem" />
        </Flex>
        <Flex justify="space-between" align="flex-end">
          <Skeleton width="30%" height="1rem" />
          <Skeleton width="30%" height="1rem" />
        </Flex>
      </Box>
    </article>
  ));
};
