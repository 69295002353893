'use client';

import { usePrint } from '@/lib/hooks';
import { PageProps, PageView } from '@/lib/types';
import { ENDPOINT, createRandomId, createURL } from '@/lib/utils';
import { Body, Breadcrumb, CustomLink, Section, SliderArrow, ZoomImage } from '@/ui';
import { Content } from '@/widgets';
import { Box, Container, Group, Pill } from '@mantine/core';
import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import classnames from './view.module.css';

type Props = {
  view: PageProps & {
    data: PageView;
  };
  slider: {
    images: string[];
    blurredImages: string[];
  };
  blurredBodyImage: string;
};

const SliderImage = ({ src, alt, className, ...props }: ImageProps) => {
  const [error, setError] = useState(false);

  if (error) return null;

  return (
    <Box className={classnames.carousel__item}>
      <Image src={src} alt={alt} fill className={className} onError={() => setError(true)} {...props} />
    </Box>
  );
};

export function NewsView({ view, slider, blurredBodyImage = '' }: Props) {
  const { ref, handlePrint } = usePrint();

  const categoryLink = `/news/${view?.data?.category_code}`;

  const breadcrumbItems = [
    { title: view?.breadcrumb, href: `/general-menu/${view?.menu_id}` },
    { title: view?.data?.category_title, href: categoryLink },
  ];

  const carouselSettings: Settings = {
    infinite: true,
    autoplay: true,
    dots: true,
    pauseOnHover: true,
    speed: 1500,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderArrow classList={classnames.carousel__prevBtn} />,
    nextArrow: <SliderArrow classList={classnames.carousel__nextBtn} />,
    accessibility: false,
  };

  return (
    <Container>
      <Section
        rightMenu={{
          isActive: true,
          id: view?.menu_id,
          activeItem: { id: view?.menu_item_id },
        }}
      >
        <Content ref={ref}>
          <Content.Header details={{ handlePrint, ageLimit: view?.data?.age_limit }}>
            <Breadcrumb items={breadcrumbItems} />
          </Content.Header>
          <Content.Title className="normal-case">{view?.data?.title}</Content.Title>
          <Content.Body isShareable views={view?.data?.views} updatedAt={view?.data?.updated_date}>
            <h3 className={classnames.date}>
              {view.data.category_code === 'events' ? view.data.date?.split(' ')?.[0] : view.data.date} <span>/</span>{' '}
              <CustomLink href={categoryLink || '/'} className="hover:underline">
                {view?.data?.category_title}
              </CustomLink>
            </h3>
            <ZoomImage>
              {view?.data?.anons?.trim()?.length > 0 ? <Body content={view?.data?.anons} className="!pb-0" /> : null}

              {Array.isArray(slider?.images) && slider?.images?.length > 0 ? (
                <Box className={classnames.carousel}>
                  <Slider {...carouselSettings} className={classnames['carousel-without-print']}>
                    {slider?.images.map((item, index) => (
                      <SliderImage
                        key={createRandomId()}
                        src={`${ENDPOINT}/uploads/${item}`}
                        alt={view?.data?.title}
                        fill
                        className={classnames.image}
                        placeholder={slider?.blurredImages[index] ? 'blur' : 'empty'}
                        blurDataURL={slider?.blurredImages[index] || ''}
                      />
                    ))}
                  </Slider>
                  <Box className={clsx(classnames.carousel__item, classnames['carousel-with-print'])}>
                    <Image
                      src={`${ENDPOINT}/uploads/${slider?.images[0]}`}
                      alt={view?.data?.title}
                      fill
                      className={classnames.image}
                      placeholder={slider?.blurredImages[0] ? 'blur' : 'empty'}
                      blurDataURL={slider?.blurredImages[0] || ''}
                    />
                  </Box>
                </Box>
              ) : view?.data?.body_image?.trim()?.length > 0 ? (
                <Box className={classnames.carousel}>
                  <Box className={classnames.carousel__item}>
                    <Image
                      src={view?.data?.body_image}
                      alt={view?.data?.title}
                      fill
                      className={clsx(classnames.image, classnames.bordered)}
                      placeholder={blurredBodyImage ? 'blur' : 'empty'}
                      blurDataURL={blurredBodyImage || ''}
                    />
                  </Box>
                </Box>
              ) : null}

              {view?.data?.body?.trim()?.length > 0 ? <Body content={view?.data?.body} /> : null}
            </ZoomImage>
            {view?.data?.activity?.length > 0 ? (
              <Group wrap="wrap" py="sm">
                {view?.data?.activity?.map((item) => (
                  <Pill
                    key={createRandomId()}
                    size="lg"
                    classNames={{ root: classnames.pill, label: classnames.pill__label }}
                    renderRoot={(props) => <CustomLink href={createURL(item?.url)} {...props} />}
                  >
                    {item?.title}
                  </Pill>
                ))}
              </Group>
            ) : null}
          </Content.Body>
        </Content>
      </Section>
    </Container>
  );
}
