'use client';

import { EyeIcon } from '@/icons';
import { Flex } from '@mantine/core';
import classnames from './views.module.css';

export function Views({ variant = 'primary', count }: { variant?: 'primary' | 'secondary'; count: number | null }) {
  return count ? (
    <Flex align="center" gap="xs" className={`${classnames.views} ${classnames[variant]}`}>
      <EyeIcon />
      <p>{count}</p>
    </Flex>
  ) : null;
}
