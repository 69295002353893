'use client';

import { Box } from '@mantine/core';
import mediumZoom from 'medium-zoom';
import { PropsWithChildren, useEffect } from 'react';

export function ZoomImage({ children }: PropsWithChildren) {
  useEffect(() => {
    const zoom = mediumZoom('.data-zoomable img');
    return () => {
      zoom.detach();
    };
  }, []);

  return <Box className="data-zoomable">{children}</Box>;
}
