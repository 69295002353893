'use client';

import { ClockIcon, EnvelopeFilledIcon, LocationIcon, PhoneIcon, ShareIcon, WebsiteIcon } from '@/icons';
import { Guide, SocialMedia } from '@/lib/types';
import { createRandomId } from '@/lib/utils';
import { CustomLink, SocialMediaIcon } from '@/ui';
import { Box, Button, Center, Flex, Skeleton, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { clsx } from 'clsx';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useState } from 'react';
import classnames from './employee.module.css';
import EmployeeModal from './EmployeeModal';

type Props = Guide & {
  isMinister?: boolean;
  isInnerGuide?: boolean;
  children?: React.ReactNode;
  className?: string;
};

export function Employee(employee: Props) {
  const t = useTranslations();
  const [opened, { open, close }] = useDisclosure(false);
  const [modalContent, setModalContent] = useState({ title: '', fullname: '', content: '' });

  const handleModalOpen = (title: string, fullname: string, content: string) => () => {
    open();
    setModalContent({ title, fullname, content });
  };

  const employeeInfo = (
    <Flex className={classnames.employee__info}>
      {employee?.phone?.trim()?.length > 0 ? (
        <CustomLink href={`tel:${employee?.phone}`} target="_blank">
          <PhoneIcon className={clsx(classnames.info__icon, classnames.phone__icon)} />
          <i>{employee?.phone}</i>
        </CustomLink>
      ) : null}
      {employee?.email?.trim()?.length > 0 ? (
        <CustomLink href={`mailto:${employee?.email}`} target="_blank">
          <EnvelopeFilledIcon className={classnames.info__icon} />
          <i>{employee?.email}</i>
        </CustomLink>
      ) : null}
      {employee?.socials?.length > 0 ? (
        <Flex align="center" gap="xs">
          <ShareIcon className={classnames.info__icon} />
          <Flex gap="xs" wrap="wrap">
            {employee?.socials?.map((social) => (
              <SocialMediaIcon
                key={createRandomId()}
                type={social?.title?.toLowerCase() as SocialMedia}
                url={social?.link}
                circle
              />
            ))}
          </Flex>
        </Flex>
      ) : null}
      {employee?.website?.trim()?.length > 0 ? (
        <CustomLink
          href={employee?.website?.includes('http') ? employee?.website : `https://${employee?.website}`}
          target="_blank"
        >
          <WebsiteIcon className={classnames.info__icon} />
          <i>{employee?.website}</i>
        </CustomLink>
      ) : null}
      {employee?.address?.trim()?.length > 0 ? (
        <Box className={classnames.info}>
          <Center w={24} className="shrink-0">
            <LocationIcon className={clsx(classnames.info__icon, classnames.address__icon)} />
          </Center>
          <Text>{employee?.address}</Text>
        </Box>
      ) : null}
      {employee?.activity?.length > 0 && !employee?.isMinister ? (
        <Flex columnGap="sm" wrap="wrap">
          {employee?.activity?.map((activity, i) => (
            <CustomLink key={activity?.id} href={`/activity_page/${activity?.code}` || '/'} className="hover:underline">
              <i>
                {activity?.title}
                {i < employee?.activity?.length - 1 ? ',' : null}
              </i>
            </CustomLink>
          ))}
        </Flex>
      ) : null}
      {employee?.reception_day?.trim()?.length > 0 ? (
        <Box className={classnames.info}>
          <ClockIcon className={classnames.info__icon} />
          <i className="max-w-xs">{employee?.reception_day}</i>
        </Box>
      ) : null}
    </Flex>
  );

  return (
    <>
      <Flex direction="column" className={clsx(classnames.wrapper, 'w-full p-8 xl:p-4', employee.className)}>
        <Box
          className={clsx(classnames.employee, { [classnames['inner']]: employee?.isInnerGuide }, 'gap-8 xl:gap-4 ')}
        >
          {employee?.image ? (
            <Box className={classnames.employee__img}>
              <Image
                src={!employee?.image || employee?.image?.endsWith('/') ? '/img/icons/employee.svg' : employee?.image}
                alt={employee?.title}
                fill
                sizes="(max-width: 1200px) 320px"
                placeholder="blur"
                blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOUlEQVR4nDWKQQkAIAwAF2kxFEssw+qtw4VQe0wm+jg4jhNgABtYQJcSd08zS2DeoKpZ/NDfPiOiHfxrLI2FP4/BAAAAAElFTkSuQmCC"
              />
            </Box>
          ) : null}
          <Flex direction="column">
            <h3 className={classnames.employee__fullname}>{employee?.title}</h3>
            <p className={classnames.employee__position}>{employee?.position_title}</p>
            <Flex
              gap="sm"
              pt="md"
              pb={employee?.isInnerGuide ? 'md' : 0}
              wrap="wrap"
              className={classnames.employee__functionalities}
            >
              {employee?.description?.trim() ? (
                <Button bg="primary" onClick={handleModalOpen(t('vazifalari'), employee?.title, employee?.description)}>
                  {t('vazifalari')}
                </Button>
              ) : null}
              {employee?.functions?.trim() ? (
                <Button bg="primary" onClick={handleModalOpen(t('Biografiya'), employee?.title, employee?.functions)}>
                  {t('Biografiya')}
                </Button>
              ) : null}
            </Flex>
            {employee?.isInnerGuide ? employeeInfo : null}
          </Flex>
          {!employee?.isInnerGuide ? employeeInfo : null}
        </Box>
        {employee?.children}
      </Flex>
      {employee?.description?.trim() || employee?.functions?.trim() ? (
        <EmployeeModal opened={opened} onClose={close} {...modalContent} />
      ) : null}
    </>
  );
}

Employee.Loading = function EmployeeLoading({ count = 1 }: { count?: number }) {
  return Array.from({ length: count }).map((_, i) => (
    <Box key={i} className={clsx(classnames.wrapper, classnames.employee, 'gap-8 p-8 xl:gap-4 xl:p-4')}>
      <Skeleton className={classnames.employee__img} />
      <Flex w="100%" direction="column" gap="sm" className="xl:items-center">
        <Skeleton width="90%" height="1.8rem" />
        <Skeleton width="70%" height="1.6rem" />
      </Flex>
      <Flex className={classnames.employee__info}>
        <Flex align="center" gap="xs" className="w-56">
          <Skeleton width="32px" height="32px" className="shrink-0" />
          <Skeleton width="100%" height="1.8rem" />
        </Flex>
        <Flex align="center" gap="xs" className="w-56">
          <Skeleton width="32px" height="32px" className="shrink-0" />
          <Skeleton width="100%" height="1.8rem" />
        </Flex>
        <Flex align="center" gap="xs" className="w-56">
          <Skeleton width="32px" height="32px" className="shrink-0" />
          <Skeleton width="100%" height="1.8rem" />
        </Flex>
      </Flex>
    </Box>
  ));
};
