'use client';

import { Box, Button, Flex, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import { clsx } from 'clsx';
import { useLocale, useTranslations } from 'next-intl';
import sanitize from 'sanitize-html';

import { CloseIcon, ErrorIcon, SuccessIcon } from '@/icons';
import { useCaptcha } from '@/lib/hooks';
import { AUTHORITY_CODE, sanitizeInput } from '@/lib/utils';
import { Captcha } from '@/widgets';
import classnames from './feedback-form.module.css';

type FormValues = {
  full_name: string;
  email: string;
  phone: string;
  address: string;
  work_address: string;
  position: string;
  question_title: string;
  question_text: string;
  verifyCode: string;
};

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const closeButtonProps = {
  bg: 'transparent',
  icon: <CloseIcon />,
};

export function FeedbackForm({ isAntiCorruption = false }: { isAntiCorruption?: boolean }) {
  const t = useTranslations();
  const locale = useLocale();
  const { captcha, getCaptcha, isLoading } = useCaptcha();

  const checkLength = (value: string) => {
    return value?.trim()?.length > 0 ? null : t('Bu katakcha to‘ldirilishi shart');
  };

  const checkEmail = (value: string) => {
    if (value?.trim()?.length > 0) return emailRegex.test(value) ? null : t('Email noto‘g‘ri formatda');
    return t('Bu katakcha to‘ldirilishi shart');
  };

  const { onSubmit, reset, getInputProps, setFieldValue, errors } = useForm<FormValues>({
    initialValues: {
      full_name: '',
      email: '',
      phone: '',
      address: '',
      work_address: '',
      position: '',
      question_title: '',
      question_text: '',
      verifyCode: '',
    },
    validate: {
      email: (value) => checkEmail(value),
      full_name: (value) => checkLength(value),
      question_title: (value) => checkLength(value),
      question_text: (value) => checkLength(value),
      verifyCode: (value) => checkLength(value),
    },
    validateInputOnBlur: true,
  });

  const handleSubmit = async (formValues: FormValues) => {
    const sanitizedValues = Object.fromEntries(
      Object.keys(formValues).map((key) => {
        const value = sanitizeInput(formValues[key as keyof FormValues]);
        return [key, value];
      })
    );

    try {
      let { data } = await axios.post(
        '/authorities/lists/feedback',
        { ...sanitizedValues, type_id: isAntiCorruption ? 2 : 1 },
        { headers: { language: locale, code: AUTHORITY_CODE } }
      );

      if (data?.data?.status) {
        notifications.show({
          message: data?.data?.result.message,
          bg: 'green',
          icon: <SuccessIcon />,
          classNames: {
            root: classnames.notification,
            description: classnames.notification__description,
            icon: clsx(classnames.notification__icon, classnames.light),
            closeButton: clsx(classnames.notification__close, classnames.light),
          },
          closeButtonProps,
        });
        reset();
        getCaptcha();
      } else {
        Object.values((data?.data?.error as string[]) || {}).map((error) => {
          notifications.show({
            message: error?.[0],
            bg: 'red',
            icon: <ErrorIcon />,
            classNames: {
              root: classnames.notification,
              description: classnames.notification__description,
              icon: clsx(classnames.notification__icon, classnames.light),
              closeButton: clsx(classnames.notification__close, classnames.light),
            },
            closeButtonProps: {
              bg: 'transparent',
              icon: <CloseIcon />,
            },
          });
        });
        setFieldValue('verifyCode', '');
        getCaptcha();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const resetCaptcha = () => {
    setFieldValue('verifyCode', '');
    getCaptcha();
  };

  return (
    <Box maw={900} py="xl" mx="auto">
      <p
        className={classnames.warning}
        dangerouslySetInnerHTML={{
          __html: sanitize(t('belgisi-bilan-belgilangan-katakchalar-to-ldirilishi-shart')?.replace('*', '<b>*</b>')),
        }}
      ></p>
      <form onSubmit={onSubmit(handleSubmit)} autoComplete="off" className="flex flex-col gap-5 pt-5">
        <TextInput
          classNames={classnames}
          size="lg"
          withAsterisk
          label={t('f-i-sh')}
          placeholder={t('f-i-sh')}
          {...getInputProps('full_name')}
        />
        <TextInput
          classNames={classnames}
          size="lg"
          withAsterisk
          label={t('Elektron pochta')}
          {...getInputProps('email')}
        />
        <TextInput
          classNames={classnames}
          size="lg"
          label={t('Telefon')}
          placeholder="+998 97 000-00-00"
          {...getInputProps('phone')}
        />
        <TextInput classNames={classnames} size="lg" label={t('Manzil')} {...getInputProps('address')} />
        <TextInput classNames={classnames} size="lg" label={t('Ish joyi')} {...getInputProps('work_address')} />
        <TextInput classNames={classnames} size="lg" label={t('Lavozim')} {...getInputProps('position')} />
        <TextInput
          classNames={classnames}
          size="lg"
          withAsterisk
          label={t('Savol mavzusi')}
          {...getInputProps('question_title')}
        />
        <Textarea
          classNames={classnames}
          withAsterisk
          placeholder={t('Matn kiriting')}
          label={t('Savol matni (3000 belgigacha)')}
          rows={5}
          {...getInputProps('question_text')}
        />
        <Flex justify="space-between" align="flex-end" mt="md" wrap="wrap" gap="md">
          <Captcha
            isLoading={isLoading}
            label={t('Spamdan himoya')}
            styles={classnames}
            reset={resetCaptcha}
            captchaImg={captcha}
            error={errors.verifyCode as string}
            inputProps={getInputProps('verifyCode')}
          />
          <Button type="submit" bg="primary" size="md" px="xl">
            {t('Yuborish')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
