'use client';

import { usePathname } from '@/i18n/routing';
import { useQueryString } from '@/lib/hooks';
import { Group, Pagination } from '@mantine/core';
import { useLocale, useTranslations } from 'next-intl';
import { useRouter } from 'next-nprogress-bar';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import classnames from './pagination.module.css';

export function PaginationWidget({ totalPage = 0 }: { totalPage: number | undefined }) {
  const t = useTranslations();
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();
  const searchParams = useSearchParams();
  const createQueryString = useQueryString(searchParams.toString());

  const initialPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const [activePage, setActivePage] = useState(initialPage);

  const handlePageChange = (page: number) => {
    if (!page) return;

    setActivePage(page);
    const PATH = `/${locale}${pathname}?${createQueryString({ page: page?.toString() })}`;
    router.replace(PATH, { scroll: false });
  };

  useEffect(() => {
    setActivePage(initialPage);
  }, [searchParams.get('page')]);

  if ((totalPage || 1) <= 1) return null;

  return (
    <Pagination.Root
      value={activePage}
      onChange={handlePageChange}
      total={totalPage || 1}
      siblings={2}
      classNames={classnames}
      className="p-10 grid place-items-center"
    >
      <Group gap={8} justify="center">
        <Pagination.Previous aria-label={t('Orqaga')} />
        <Pagination.Items />
        <Pagination.Next aria-label={t('Oldinga')} />
      </Group>
    </Pagination.Root>
  );
}
