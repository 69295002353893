'use client';

import { Link } from '@/i18n/routing';
import { ComponentProps, forwardRef } from 'react';

const CustomLink = forwardRef<HTMLAnchorElement, ComponentProps<typeof Link>>((props, ref) => {
  return <Link prefetch={false} ref={ref} {...props} />;
});

CustomLink.displayName = 'CustomLink';

export { CustomLink };
